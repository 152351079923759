import { Card, CardBody, CardHeader, Col, FormGroup, Input, InputGroup, InputGroupText, Label, Row, Table, Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import DatePicker from "react-datepicker";
import Swal from 'sweetalert2'
import DataTable from 'react-data-table-component';
import "react-datepicker/dist/react-datepicker.css";
import React, { useEffect, useState, useContext } from "react";
import { UserContext } from '../../../../context/UserProvider';
import Filtro from '../../../../componentes/filtro';
import * as XLSX from "xlsx"
import { FormatMoney } from 'format-money-js'
import filtro from "../../../../componentes/filtro";
const tabespacio = '\u00A0';

const modeloInicio = [{
    referencia: "",
    descripcion: "",
    bodega: "",
    existencia: ""

}]

const ReporteVenta = (props) => {
    const [fechaInicio, setFechaInicio] = useState(new Date().getFullYear() + "-" + ("0" + (new Date().getMonth() + 1)).slice(-2) + "-" + ("0" + new Date().getDate()).slice(-2));
    const [fechaFin, setFechaFin] = useState(new Date().getFullYear() + "-" + ("0" + (new Date().getMonth() + 1)).slice(-2) + "-" + ("0" + new Date().getDate()).slice(-2));
    const [fechahoy, setfechahoy] = useState(new Date().getFullYear() + "-" + ("0" + (new Date().getMonth() + 1)).slice(-2) + "-" + ("0" + new Date().getDate()).slice(-2));

    const [abrirbuscar, setabrirbuscar] = useState(false);
    const [pendiente, setPendiente] = useState(false);
    const [concepto, setconcepto] = useState(false);
    const [centrodecosto, setcentrodecosto] = useState(false);
    const [cuenta, setcuenta] = useState(false);
    const [filtros, setfiltros] = useState(false);
    const [ventas, setVentas] = useState(modeloInicio)
    const [ventasconceptos, setventasconceptos] = useState(modeloInicio)
    const [ventascentrodecostos, setventascentrodecostos] = useState(modeloInicio)
    const [ventascuenta, setventascuenta] = useState(modeloInicio)
    const { user, cerrarSession } = useContext(UserContext);
    const [fm, setfm] = useState(new FormatMoney({ symbol: '', decimals: 2 }));
    const [fm3, setfm3] = useState(new FormatMoney({ symbol: '$ ', decimals: 2 }));
    const [nivel, setnivel] = useState("-");
    const [cuentainicioseleccionado, setcuentainicioseleccionado] = useState("-");
    const [centrodecostoinicioseleccionado, setcentrodecostoinicioseleccionado] = useState("-");
    const [tipodocumentofinseleccionado, settipodocumentofinseleccionado] = useState("-");
    const [cuentafinseleccionado, setcuentafinseleccionado] = useState("-");
    const [centrodecostofinseleccionado, setcentrodecostofinseleccionado] = useState("-");
    const [terceroinicioseleccionado, setterceroinicioseleccionado] = useState("-");
    const [tercerofinseleccionado, settercerofinseleccionado] = useState("-");
    const [tipodecolumna, settipodecolumna] = useState("1");
    const [sucursal, setsucursal] = useState("-");
    const buscar = () => {

        setPendiente(true)
        // let options = { year: 'numeric', month: '2-digit', day: '2-digit' };

        // let _fechaInicio = fechaInicio.toLocaleDateString('es-PE', options)
        // let _fechaFin = fechaFin.toLocaleDateString('es-PE', options)
        let dt = JSON.parse(user)
        var coincidencia = ''

        var emp = dt.empresa;
        var rule = emp.empresa.identificador;
        if (rule == "") {
            rule = "X.X.XX.XX.XX.XX"
        }
        const api = fetch(props.servidor + "/ContabilidadApi/libroauxiliar/" + fechaInicio + "/" + fechaFin + "/" + nivel + "/" + centrodecostoinicioseleccionado + "/" + centrodecostofinseleccionado + "/" + cuentainicioseleccionado + "/" + cuentafinseleccionado + "/" + rule + '/' + terceroinicioseleccionado + '/' + tercerofinseleccionado + '/' + sucursal + '?llave=' + dt.usuario.llaveempresa + '')
            .then((response) => {
                return response.ok ? response.json() : Promise.reject(response);
            })
            .then((dataJson) => {
                var data = dataJson;
                setPendiente(false)
                if (data.length < 1) {
                    Swal.fire(
                        'Opps!',
                        'No se encontraron resultados',
                        'warning'
                    )
                }
                setVentas(data);
                settipodecolumna("1")
            }).catch((error) => {
                setVentas([]);
                Swal.fire(
                    'Opps!',
                    'No se pudo encontrar información',
                    'error'
                )
            })
    }

    const columnsnormal = [
        {
            name: 'Cuenta',
            selector: row => row.cuenta,
            sortable: true,
        },
        {
            name: 'Nombre',
            selector: row => row.descripcion,
            sortable: true,
        },
        {
            name: 'Fecha',
            selector: row => row.fecha,
            sortable: true,
        },
        {
            name: 'Tipo Doc',
            selector: row => row.tipodoc,
            sortable: true,
        },
        {
            name: 'Documento',
            selector: row => row.ndocumento,
            sortable: true,
        },
        {
            name: 'Documento Ref',
            selector: row => row.documentoref,
            sortable: true,
        },
        {
            name: 'Acreedor',
            selector: row => row.acreedor,
            sortable: true,
            grow: 2,
            width: '200px',
        },
        {
            name: 'Nombre',
            selector: row => row.nomacreedor,
            sortable: true,
            grow: 2,
            width: '200px',
        },
        {
            name: 'Descripcion',
            selector: row => row.descripcion,
            sortable: true,
            grow: 2,
            width: '200px',
        },
        {
            name: 'Centro Costo',
            selector: row => row.codcentrocostos,
            sortable: true,
            grow: 2,
            width: '200px',
        },
        {
            name: 'Saldo Anterior',
            selector: row => fm.from(Number(row.saldoanterior)),
            sortable: true,
            width: '200px',
        },
        {
            name: 'Debito',
            selector: row => fm.from(Number(row.debito)),
            sortable: true,
            width: '200px',
        },
        {
            name: 'Credito',
            selector: row => fm.from(Number(row.credito)),
            sortable: true,
            width: '200px',
        },
        {
            name: 'Saldo Actual',
            selector: row => fm.from(Number(row.saldoactual)),
            sortable: true,
            grow: 2,
            width: '200px',
        }
    ];
    const columns2 = [
        {
            name: 'Tercero',
            selector: row => row.acreedor,
            sortable: true,
        },
        {
            name: 'Nombre',
            selector: row => row.nomacreedor,
            sortable: true,
        },
        {
            name: 'Total Debito',
            selector: row => fm3.from(row.totaldebito),
            sortable: true,
        },
        {
            name: 'Total Credito',
            selector: row => fm3.from(row.totalcredito),
            sortable: true,
        }
    ];

    const columns3 = [
        {
            name: 'Tipo Documento',
            selector: row => row.tipodoc,
            sortable: true,
        },
        {
            name: 'Fecha',
            selector: row => row.fecha,
            sortable: true,
        },
        {
            name: 'Numero',
            selector: row => row.ndocumento,
            sortable: true,
        },
        {
            name: 'Debito',
            selector: row => fm3.from(row.debito),
            sortable: true,
        },
        {
            name: 'Credito',
            selector: row => fm3.from(row.credito),
            sortable: true,
        }
    ];
    const rowPreExpanded2 = ({ data }) => {
        return <>
            <DataTable
                progressPending={pendiente}
                columns={columns2}
                data={data.terceros}
                // noTableHead={true}
                expandableRows
                pagination
                conditionalRowStyles={conditionalRowStyles3}
                expandableRowsComponent={rowPreExpanded233}
            />
        </>
    }
    const rowPreExpanded233 = ({ data }) => {
        return <>
            <DataTable
                progressPending={pendiente}
                columns={columns3}
                data={data.movimientos}
                // noTableHead={true}
                pagination
                conditionalRowStyles={conditionalRowStyles322}
            />
        </>
    }
    const rowPreExpanded3 = () => {
        return <>
            <>

            </>
        </>
    }
    const conditionalRowStyles322 = [

        {
            when: row => true,
            style: {
                // fontSize: '10px',
                color: '#188BAD'

            }
        }
    ];
    const conditionalRowStyles3 = [

        {
            when: row => true,
            style: {
                // fontSize: '10px',
                color: '#188BAD'

            }
        }
    ];
    const buscarpdfcentrocostotercero = () => {

        setPendiente(true)
        // let options = { year: 'numeric', month: '2-digit', day: '2-digit' };

        // let _fechaInicio = fechaInicio.toLocaleDateString('es-PE', options)
        // let _fechaFin = fechaFin.toLocaleDateString('es-PE', options)
        let dt = JSON.parse(user)
        var coincidencia = ''

        var emp = dt.empresa;
        var rule = emp.empresa.identificador;
        if (rule == "") {
            rule = "X.X.XX.XX.XX.XX"
        }
        const api = fetch(props.servidor + "/ContabilidadApi/libroauxiliarcentrocostoterceropdf/" + fechaInicio + "/" + fechaFin + "/" + nivel + "/" + centrodecostoinicioseleccionado + "/" + centrodecostofinseleccionado + "/" + cuentainicioseleccionado + "/" + cuentafinseleccionado + "/" + rule + '/' + terceroinicioseleccionado + '/' + tercerofinseleccionado + '/' + dt.empresa.empresa.nit + '/' + sucursal + '?llave=' + dt.usuario.llaveempresa + '')
            .then((response) => {
                return response.ok ? response.json() : Promise.reject(response);
            })
            .then((dataJson) => {
                var data = dataJson;
                setPendiente(false)
                setVentas(data)
            }).catch((error) => {
                setVentas([]);
                Swal.fire(
                    'Opps!',
                    'No se pudo encontrar información',
                    'error'
                )
            })
    }
    const buscarpdfcentrocosto = () => {

        setPendiente(true)
        // let options = { year: 'numeric', month: '2-digit', day: '2-digit' };

        // let _fechaInicio = fechaInicio.toLocaleDateString('es-PE', options)
        // let _fechaFin = fechaFin.toLocaleDateString('es-PE', options)
        let dt = JSON.parse(user)
        var coincidencia = ''

        var emp = dt.empresa;
        var rule = emp.empresa.identificador;
        if (rule == "") {
            rule = "X.X.XX.XX.XX.XX"
        }
        const api = fetch(props.servidor + "/ContabilidadApi/libroauxiliarcentrocostopdf/" + fechaInicio + "/" + fechaFin + "/" + nivel + "/" + centrodecostoinicioseleccionado + "/" + centrodecostofinseleccionado + "/" + cuentainicioseleccionado + "/" + cuentafinseleccionado + "/" + rule + '/' + terceroinicioseleccionado + '/' + tercerofinseleccionado + '/' + dt.empresa.empresa.nit + '/' + sucursal + '?llave=' + dt.usuario.llaveempresa + '')
            .then((response) => {
                return response.ok ? response.json() : Promise.reject(response);
            })
            .then((dataJson) => {
                var data = dataJson;
                const bytes = atob(data.pdf);

                // Crear un arreglo de bytes a partir de la cadena decodificada
                const byteArray = new Uint8Array(bytes.length);
                for (let i = 0; i < bytes.length; i++) {
                    byteArray[i] = bytes.charCodeAt(i);
                }

                // Crear un Blob a partir del arreglo de bytes
                const blob = new Blob([byteArray], { type: 'application/pdf' });
                // Crear una URL para el Blob
                const url = window.URL.createObjectURL(blob);
                // Crear un enlace para descargar el archivo PDF
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'Libro_Auxiliar_Centro_de_Costo.pdf');
                // Simular clic en el enlace para iniciar la descarga
                document.body.appendChild(link);
                link.click();
                // Liberar el objeto URL creado
                window.URL.revokeObjectURL(url);
                setPendiente(false)
                if (data.datos.length < 1) {
                    Swal.fire(
                        'Opps!',
                        'No se encontraron resultados',
                        'warning'
                    )
                }
                console.log(data.datos)
            }).catch((error) => {
                setVentas([]);
                Swal.fire(
                    'Opps!',
                    'No se pudo encontrar información',
                    'error'
                )
            })
    }
    const buscarpdf = () => {

        setPendiente(true)
        // let options = { year: 'numeric', month: '2-digit', day: '2-digit' };

        // let _fechaInicio = fechaInicio.toLocaleDateString('es-PE', options)
        // let _fechaFin = fechaFin.toLocaleDateString('es-PE', options)
        let dt = JSON.parse(user)
        var coincidencia = ''

        var emp = dt.empresa;
        var rule = emp.empresa.identificador;
        if (rule == "") {
            rule = "X.X.XX.XX.XX.XX"
        }
        const api = fetch(props.servidor + "/ContabilidadApi/libroauxiliarpdf/" + fechaInicio + "/" + fechaFin + "/" + nivel + "/" + centrodecostoinicioseleccionado + "/" + centrodecostofinseleccionado + "/" + cuentainicioseleccionado + "/" + cuentafinseleccionado + "/" + rule + '/' + terceroinicioseleccionado + '/' + tercerofinseleccionado + '/' + dt.empresa.empresa.nit + '/' + sucursal + '?llave=' + dt.usuario.llaveempresa + '')
            .then((response) => {
                return response.ok ? response.json() : Promise.reject(response);
            })
            .then((dataJson) => {
                var data = dataJson;
                const bytes = atob(data.pdf);

                // Crear un arreglo de bytes a partir de la cadena decodificada
                const byteArray = new Uint8Array(bytes.length);
                for (let i = 0; i < bytes.length; i++) {
                    byteArray[i] = bytes.charCodeAt(i);
                }

                // Crear un Blob a partir del arreglo de bytes
                const blob = new Blob([byteArray], { type: 'application/pdf' });
                // Crear una URL para el Blob
                const url = window.URL.createObjectURL(blob);
                // Crear un enlace para descargar el archivo PDF
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'Libro_Auxiliar.pdf');
                // Simular clic en el enlace para iniciar la descarga
                document.body.appendChild(link);
                link.click();
                // Liberar el objeto URL creado
                window.URL.revokeObjectURL(url);
                setPendiente(false)
                if (data.datos.length < 1) {
                    Swal.fire(
                        'Opps!',
                        'No se encontraron resultados',
                        'warning'
                    )
                }
                console.log(data.datos)
            }).catch((error) => {
                setVentas([]);
                Swal.fire(
                    'Opps!',
                    'No se pudo encontrar información',
                    'error'
                )
            })
    }
    const buscarexcell = () => {

        setPendiente(true)
        // let options = { year: 'numeric', month: '2-digit', day: '2-digit' };

        // let _fechaInicio = fechaInicio.toLocaleDateString('es-PE', options)
        // let _fechaFin = fechaFin.toLocaleDateString('es-PE', options)
        let dt = JSON.parse(user)
        var coincidencia = ''

        var emp = dt.empresa;
        var rule = emp.empresa.identificador;
        if (rule == "") {
            rule = "X.X.XX.XX.XX.XX"
        }
        const api = fetch(props.servidor + "/ContabilidadApi/libroauxiliarexcel/" + fechaInicio + "/" + fechaFin + "/" + nivel + "/" + centrodecostoinicioseleccionado + "/" + centrodecostofinseleccionado + "/" + cuentainicioseleccionado + "/" + cuentafinseleccionado + "/" + rule + '/' + terceroinicioseleccionado + '/' + tercerofinseleccionado + '/' + dt.empresa.empresa.nit + '/' + sucursal + '?llave=' + dt.usuario.llaveempresa + '')
            .then((response) => {
                return response.ok ? response.json() : Promise.reject(response);
            })
            .then((dataJson) => {
                var data = dataJson;
                const bytes = atob(data.pdf);

                // Crear un arreglo de bytes a partir de la cadena decodificada
                const byteArray = new Uint8Array(bytes.length);
                for (let i = 0; i < bytes.length; i++) {
                    byteArray[i] = bytes.charCodeAt(i);
                }

                // Crear un Blob a partir del arreglo de bytes
                const blob = new Blob([byteArray], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                // Crear una URL para el Blob
                const url = window.URL.createObjectURL(blob);
                // Crear un enlace para descargar el archivo PDF
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'Libro_Auxiliar.xlsx');
                // Simular clic en el enlace para iniciar la descarga
                document.body.appendChild(link);
                link.click();
                // Liberar el objeto URL creado
                window.URL.revokeObjectURL(url);
                setPendiente(false)
                if (data.datos.length < 1) {
                    Swal.fire(
                        'Opps!',
                        'No se encontraron resultados',
                        'warning'
                    )
                }
                console.log(data.datos)
            }).catch((error) => {
                setVentas([]);
                Swal.fire(
                    'Opps!',
                    'No se pudo encontrar información',
                    'error'
                )
            })
    }

    const buscarexcellcosto = () => {

        setPendiente(true)
        // let options = { year: 'numeric', month: '2-digit', day: '2-digit' };

        // let _fechaInicio = fechaInicio.toLocaleDateString('es-PE', options)
        // let _fechaFin = fechaFin.toLocaleDateString('es-PE', options)
        let dt = JSON.parse(user)
        var coincidencia = ''

        var emp = dt.empresa;
        var rule = emp.empresa.identificador;
        if (rule == "") {
            rule = "X.X.XX.XX.XX.XX"
        }
        const api = fetch(props.servidor + "/ContabilidadApi/libroauxiliarexcelcentrocosto/" + fechaInicio + "/" + fechaFin + "/" + nivel + "/" + centrodecostoinicioseleccionado + "/" + centrodecostofinseleccionado + "/" + cuentainicioseleccionado + "/" + cuentafinseleccionado + "/" + rule + '/' + terceroinicioseleccionado + '/' + tercerofinseleccionado + '/' + dt.empresa.empresa.nit + '/' + sucursal + '?llave=' + dt.usuario.llaveempresa + '')
            .then((response) => {
                return response.ok ? response.json() : Promise.reject(response);
            })
            .then((dataJson) => {
                var data = dataJson;
                const bytes = atob(data.pdf);

                // Crear un arreglo de bytes a partir de la cadena decodificada
                const byteArray = new Uint8Array(bytes.length);
                for (let i = 0; i < bytes.length; i++) {
                    byteArray[i] = bytes.charCodeAt(i);
                }

                // Crear un Blob a partir del arreglo de bytes
                const blob = new Blob([byteArray], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                // Crear una URL para el Blob
                const url = window.URL.createObjectURL(blob);
                // Crear un enlace para descargar el archivo PDF
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'Libro_Auxiliar.xlsx');
                // Simular clic en el enlace para iniciar la descarga
                document.body.appendChild(link);
                link.click();
                // Liberar el objeto URL creado
                window.URL.revokeObjectURL(url);
                setPendiente(false)
                if (data.datos.length < 1) {
                    Swal.fire(
                        'Opps!',
                        'No se encontraron resultados',
                        'warning'
                    )
                }
                console.log(data.datos)
            }).catch((error) => {
                setVentas([]);
                Swal.fire(
                    'Opps!',
                    'No se pudo encontrar información',
                    'error'
                )
            })
    }

    const columns = [
        {
            name: 'Centro de Costo',
            selector: row => row.codcentrocostos,
            sortable: true,
        }
    ];
    const paginationComponentOptions = {
        rowsPerPageText: 'Filas por página',
        rangeSeparatorText: 'de',
        selectAllRowsItem: true,
        selectAllRowsItemText: 'Todos',
    };
    const customStyles = {
        headCells: {
            style: {
                fontSize: '13px',
                fontWeight: 800,
            },
        },
        headRow: {
            style: {
                backgroundColor: "#eee",
            }
        }
    };

    const exportarExcel = () => {
        var wb = XLSX.utils.book_new();
        var venn = ventas.filter(p => (p.tipodoc != "" && p.tipodoc != undefined) && (p.debito != 0 || p.credito != 0))
        var ws = XLSX.utils.json_to_sheet(venn);

        XLSX.utils.book_append_sheet(wb, ws, "Reporte");
        XLSX.writeFile(wb, "Libro Auxiliar.xlsx")
    }
    const totaltotal = () => {
        var tot = 0
        for (let i = 0; i < ventas.length; i++) {
            const element = ventas[i];
            var longg = 0
            try {
                longg = element.cuenta.length
            } catch (error) {

            }
            if (longg == 1) {
                tot = tot + element.saldoactual

            }
        }
        return tot
    }
    const totalsaldoanterior = () => {
        var tot = 0
        for (let i = 0; i < ventas.length; i++) {
            const element = ventas[i];
            var longg = 0
            try {
                longg = element.cuenta.length
            } catch (error) {

            }
            if (longg == 1) {
                tot = tot + element.saldoanterior

            }
        }
        return tot
    }
    const totaldebito = () => {
        var tot = 0
        for (let i = 0; i < ventas.length; i++) {
            const element = ventas[i];
            var longg = 0
            try {
                longg = element.cuenta.length
            } catch (error) {

            }
            if (longg == 1) {
                tot = tot + element.debito

            }
        }
        return tot
    }
    const totalcredito = () => {
        var tot = 0
        for (let i = 0; i < ventas.length; i++) {
            const element = ventas[i];
            var longg = 0
            try {
                longg = element.cuenta.length
            } catch (error) {

            }
            if (longg == 1) {
                tot = tot + element.credito

            }
        }
        return tot
    }
    const totalporconcepto = (codigo) => {
        var tot = 0
        var conceptos = ventas.filter(p => p.concepto == codigo)
        for (let i = 0; i < conceptos.length; i++) {
            const element = conceptos[i];
            tot = tot + element.total
        }
        return tot
    }
    return (
        <>
            <Row>

                <Col sm={2}>
                    <FormGroup>
                        <Label style={{ fontSize: '13px' }}>Fecha Inicio:</Label>
                        <Input bsSize="sm" type="date" className="form-control form-control-sm"
                            selected={fechaInicio}
                            value={fechaInicio}
                            onChange={(e) => setFechaInicio(e.target.value)}></Input>

                    </FormGroup>
                </Col>

                <Col sm={2}>
                    <FormGroup>
                        <Label style={{ fontSize: '13px' }}>Fecha Fin:</Label>
                        <Input bsSize="sm" type="date" className="form-control form-control-sm"
                            selected={fechaFin}
                            value={fechaFin}
                            onChange={(e) => setFechaFin(e.target.value)}></Input>


                    </FormGroup>
                </Col>
                <Col sm={2}>
                    <FormGroup>
                        <Label style={{ fontSize: '13px' }}>{tabespacio}</Label>
                        <Button style={{ backgroundColor: 'darkmagenta' }} size="sm" block onClick={() => {
                            setfiltros(true)
                        }}>
                            <i className="fa fa-search" aria-hidden="true"></i> Filtros
                        </Button>
                    </FormGroup>
                </Col>
                <Col sm={2}>
                    <FormGroup>
                        <Label style={{ fontSize: '13px' }}>{tabespacio}</Label>
                        <Button color="primary" size="sm" block onClick={(e) => { setabrirbuscar(true) }}>
                            <i className="fa fa-search" aria-hidden="true"></i>Buscar
                        </Button>
                    </FormGroup>
                </Col>


            </Row>
            <Row  >


                <Col xs={3}>

                    <div className="card border-left-success shadow" style={{ backgroundColor: '#8bc34a', fontSize: '0.6rem' }}>
                        <div className="card-body p-2">
                            <div className="row no-gutters align-items-center">
                                <div className="col-12">
                                    <div className="text-xs font-weight-bold text-uppercase" style={{ color: '#FFFFFF', fontSize: '0.6rem' }}>
                                        Saldo Anterior
                                    </div>
                                    <div className="h5 mb-0 font-weight-bold " style={{ color: '#FFFFFF' }}>{fm3.from(totalsaldoanterior())}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Col>
                <Col xs={3}>

                    <div className="card border-left-success shadow" style={{ backgroundColor: '#8bc34a', fontSize: '0.6rem' }}>
                        <div className="card-body p-2">
                            <div className="row no-gutters align-items-center">
                                <div className="col-12">
                                    <div className="text-xs font-weight-bold text-uppercase" style={{ color: '#FFFFFF', fontSize: '0.6rem' }}>
                                        Debito
                                    </div>
                                    <div className="h5 mb-0 font-weight-bold " style={{ color: '#FFFFFF' }}>{fm3.from(totaldebito())}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Col>
                <Col xs={3}>

                    <div className="card border-left-success shadow" style={{ backgroundColor: '#8bc34a', fontSize: '0.6rem' }}>
                        <div className="card-body p-2">
                            <div className="row no-gutters align-items-center">
                                <div className="col-12">
                                    <div className="text-xs font-weight-bold text-uppercase" style={{ color: '#FFFFFF', fontSize: '0.6rem' }}>
                                        Credito
                                    </div>
                                    <div className="h5 mb-0 font-weight-bold " style={{ color: '#FFFFFF' }}>{fm3.from(totalcredito())}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Col>
                <Col xs={3}>

                    <div className="card border-left-success shadow" style={{ backgroundColor: '#8bc34a', fontSize: '0.6rem' }}>
                        <div className="card-body p-2">
                            <div className="row no-gutters align-items-center">
                                <div className="col-12">
                                    <div className="text-xs font-weight-bold text-uppercase" style={{ color: '#FFFFFF', fontSize: '0.6rem' }}>
                                        Saldo Actual
                                    </div>
                                    <div className="h5 mb-0 font-weight-bold " style={{ color: '#FFFFFF' }}>{fm3.from(totaltotal())}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Col>

            </Row>
            {concepto &&
                ventasconceptos.map((item, index) => {
                    return <>
                        {index == 0 && <Row>
                            <Col xs={2}>
                                <p style={{ color: 'black' }}>Concepto</p>
                            </Col>
                            <Col xs={2}>
                                <p style={{ color: 'black' }}>Total</p>
                            </Col>
                        </Row>}
                        <Row >
                            <Col xs={2}>
                                <div className="card border-left-success shadow">
                                    <div className="card-body  p-2">
                                        <div className="row no-gutters align-items-center">
                                            <div className="col mr-2">
                                                <div className="row no-gutters align-items-center">
                                                    <div className="col-auto">
                                                        <div className="h6 mb-0 mr-3 font-weight-bold " style={{ color: 'black' }}>{item.concepto}</div>
                                                    </div>
                                                    <div className="col">

                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-auto">
                                                <i className="fas fa-clipboard-list " style={{ color: 'black' }}></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </Col>
                            <Col xs={4} >

                                <div className="card border-left-primary shadow ">
                                    <div className="card-body p-2">
                                        <div className="row no-gutters align-items-center">
                                            <div className="col mr-2">
                                                <div className="h6 mb-0 font-weight-bold " style={{ color: 'black' }}>{fm.from(item.total)}</div>
                                            </div>
                                            <div className="col-auto">
                                                <i className="fas fa-dollar-sign " style={{ color: 'black' }}></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Col>


                        </Row>
                    </>
                })}
            {centrodecosto &&
                ventascentrodecostos.map((item, index) => {
                    return <>
                        {index == 0 && <Row>
                            <Col xs={3}>
                                <p style={{ color: 'black' }}>Nit</p>
                            </Col>
                            <Col xs={5}>
                                <p style={{ color: 'black' }}> Razon Social</p>
                            </Col>
                            <Col xs={4}>
                                <p style={{ color: 'black' }}> Total</p>
                            </Col>
                        </Row>}
                        <Row >
                            <Col xs={3}>
                                <div className="card border-left-info shadow ">
                                    <div className="card-body p-2">
                                        <div className="row no-gutters align-items-center">
                                            <div className="col mr-2">
                                                <div className="row no-gutters align-items-center">
                                                    <div className="col-auto">
                                                        <div className="h6 mb-0 mr-3 font-weight-bold " style={{ color: 'black' }}>{item.cliente}</div>
                                                    </div>
                                                    <div className="col">

                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-auto">
                                                <i className="fas fa-clipboard-list  " style={{ color: 'black' }}></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </Col>
                            <Col xs={5}>
                                <div className="card border-left-info shadow ">
                                    <div className="card-body p-2">
                                        <div className="row no-gutters align-items-center">
                                            <div className="col mr-2">
                                                <div className="row no-gutters align-items-center">
                                                    <div className="col-auto">
                                                        <div className="h6 mb-0 mr-3 font-weight-bold" style={{ color: 'black' }}>{item.nombrecli}</div>
                                                    </div>
                                                    <div className="col">

                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-auto">
                                                <i className="fas fa-clipboard-list " style={{ color: 'black' }}></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </Col>
                            <Col xs={4}>

                                <div className="card border-left-primary shadow ">
                                    <div className="card-body p-2">
                                        <div className="row no-gutters align-items-center">
                                            <div className="col mr-2">
                                                <div className="h6 mb-0 font-weight-bold " style={{ color: 'black' }}>{fm.from(item.total)}</div>
                                            </div>
                                            <div className="col-auto">
                                                <i className="fas fa-shopping-basket " style={{ color: 'black' }}></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Col>


                        </Row>
                    </>

                })}
            {cuenta &&
                ventascuenta.map((item, index) => {
                    return <>
                        {index == 0 && <Row>
                            <Col xs={4}>
                                <p style={{ color: 'black' }}>Nit</p>
                            </Col>
                            <Col xs={4}>
                                <p style={{ color: 'black' }}> Razon Social</p>
                            </Col>
                        </Row>}
                        <Row >
                            <Col xs={4}>
                                <div className="card border-left-info shadow ">
                                    <div className="card-body p-2">
                                        <div className="row no-gutters align-items-center">
                                            <div className="col mr-2">
                                                <div className="row no-gutters align-items-center">
                                                    <div className="col-auto">
                                                        <div className="h6 mb-0 mr-3 font-weight-bold " style={{ color: 'black' }}>{item.cuenta == "" ? "Sin cuenta" : item.cuenta}</div>
                                                    </div>
                                                    <div className="col">

                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-auto">
                                                <i className="fas fa-clipboard-list " style={{ color: 'black' }}></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </Col>

                            <Col xs={4}>

                                <div className="card border-left-primary shadow ">
                                    <div className="card-body p-2">
                                        <div className="row no-gutters align-items-center">
                                            <div className="col mr-2">
                                                <div className="h6 mb-0 font-weight-bold " style={{ color: 'black' }}>{fm.from(item.total)}</div>
                                            </div>
                                            <div className="col-auto">
                                                <i className="fas fa-shopping-basket  " style={{ color: 'black' }}></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Col>

                        </Row>
                    </>

                })}

            <Row>
                <Col sm={12}>
                    <Card>

                        <CardBody>
                            <Row>

                                <Col sm="12">
                                    {tipodecolumna == "1" ?
                                        <DataTable
                                            progressPending={pendiente}
                                            columns={columnsnormal}
                                            data={ventas}
                                            customStyles={customStyles}
                                            pagination
                                            paginationComponentOptions={paginationComponentOptions}
                                            expandableRows
                                            expandableRowsComponent={rowPreExpanded2}
                                        />
                                        :
                                        <DataTable
                                            progressPending={pendiente}
                                            columns={columns}
                                            data={ventas}
                                            customStyles={customStyles}
                                            pagination
                                            paginationComponentOptions={paginationComponentOptions}
                                            expandableRows
                                            expandableRowsComponent={rowPreExpanded2}
                                        />

                                    }





                                </Col>

                            </Row>

                        </CardBody>
                    </Card>
                </Col>
                <Filtro
                    setcentrodecostoinicioseleccionado={(e) => { setcentrodecostoinicioseleccionado(e) }}
                    setcentrodecostofinseleccionado={(e) => { setcentrodecostofinseleccionado(e) }}
                    setcuentainicioseleccionado={(e) => { setcuentainicioseleccionado(e) }}
                    setcuentafinseleccionado={(e) => { setcuentafinseleccionado(e) }}
                    setnivel={(e) => {
                        setnivel(e)
                    }
                    }
                    setterceroinicioseleccionado={(e) => { setterceroinicioseleccionado(e) }}
                    settercerofinseleccionado={(e) => { settercerofinseleccionado(e) }}
                    sucursal={sucursal}
                    setsucursal={(e) => { setsucursal(e) }}
                    centrodecostoinicioseleccionado={centrodecostoinicioseleccionado}
                    centrodecostofinseleccionado={centrodecostofinseleccionado}
                    cuentainicioseleccionado={cuentainicioseleccionado}
                    cuentafinseleccionado={cuentafinseleccionado}
                    nivel={nivel}
                    terceroinicioseleccionado={terceroinicioseleccionado}
                    tercerofinseleccionado={tercerofinseleccionado}
                    servidor={props.servidor} verModal={filtros} cerrarModal={() => { setfiltros(false) }} />
            </Row>

            <Modal size="lg" isOpen={abrirbuscar}>
                <ModalHeader>
                    Buscar
                </ModalHeader>
                <ModalBody>
                    <Row>
                        <Col sm={2}>
                            <FormGroup>
                                <Label style={{ fontSize: '13px' }}>{tabespacio}</Label>
                                <Button color="primary" size="sm" block onClick={(e) => {
                                    buscar()
                                    setabrirbuscar(false)
                                }}>
                                    <i className="fa fa-search" aria-hidden="true"></i> En Pantalla
                                </Button>
                            </FormGroup>
                        </Col>
                        <Col sm={2}>
                            <FormGroup>
                                <Label style={{ fontSize: '13px' }}>{tabespacio}</Label>
                                <Button color="primary" size="sm" block onClick={(e) => {
                                    exportarExcel()
                                }}>
                                    <i className="fa fa-search" aria-hidden="true"></i> Excel Basico de Pantalla
                                </Button>
                            </FormGroup>
                        </Col>

                        <Col sm={2}>
                            <FormGroup>
                                <Label style={{ fontSize: '13px' }}>{tabespacio}</Label>
                                <Button color="success" size="sm" block onClick={() => {
                                    buscarexcell()
                                    setabrirbuscar(false)
                                }}>
                                    <i className="fa fa-file-excel" aria-hidden="true"></i> Descargar Excel
                                </Button>
                            </FormGroup>
                        </Col>
                        <Col sm={2}>
                            <FormGroup>
                                <Label style={{ fontSize: '13px' }}>{tabespacio}</Label>
                                <Button color="success" size="sm" block onClick={() => {
                                    buscarexcellcosto()
                                    setabrirbuscar(false)
                                }}>
                                    <i className="fa fa-file-excel" aria-hidden="true"></i> Descargar Excel C.Costo
                                </Button>
                            </FormGroup>
                        </Col>

                        <Col sm={2}>
                            <FormGroup>
                                <Label style={{ fontSize: '13px' }}>{tabespacio}</Label>
                                <Button color="success" size="sm" block onClick={() => {
                                    buscarpdf()
                                    setabrirbuscar(false)
                                }}>
                                    <i className="fa fa-file-excel" aria-hidden="true"></i> PDF
                                </Button>
                            </FormGroup>
                        </Col>
                        <Col sm={2}>
                            <FormGroup>
                                <Label style={{ fontSize: '13px' }}>{tabespacio}</Label>
                                <Button color="success" size="sm" block onClick={() => {
                                    buscarpdfcentrocosto()
                                    setabrirbuscar(false)
                                }}>
                                    <i className="fa fa-file-excel" aria-hidden="true"></i> PDF Centro Costo
                                </Button>
                            </FormGroup>
                        </Col>
                        <Col sm={2}>
                            <FormGroup>
                                <Label style={{ fontSize: '13px' }}>{tabespacio}</Label>
                                <Button color="success" size="sm" block onClick={() => {
                                    buscarpdfcentrocostotercero()
                                    setabrirbuscar(false)
                                    settipodecolumna("2")

                                }}>
                                    <i className="fa fa-file-excel" aria-hidden="true"></i> PDF Especial
                                </Button>
                            </FormGroup>
                        </Col>

                    </Row>

                </ModalBody>
                <ModalFooter>
                    <Button size="sm" color="danger" onClick={() => {
                        setabrirbuscar(false)
                    }}>Cerrar</Button>
                </ModalFooter>
            </Modal>
        </>
    )
}

export default ReporteVenta;