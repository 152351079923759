import React, { useEffect, useState, useContext } from "react";
import DataTable from 'react-data-table-component';
import { InputGroup, Table, Card, CardBody, CardHeader, Button, Modal, ModalHeader, ModalBody, Label, Input, FormGroup, ModalFooter, Row, Col } from "reactstrap"
import Swal from 'sweetalert2'
import Tabs from 'react-bootstrap/Tabs'
import { UserContext } from '../context/UserProvider';
import Autosuggest from 'react-autosuggest';
import Tab from 'react-bootstrap/Tab'
import Buscar from '../componentes/Buscar'
import BuscarCuentas from '../componentes/Buscar2/Cuentas'

const modeloPais = {
    codigo: "",
    nombre: "",
}
const ImportarExportar = (props) => {
    const { user, cerrarSession } = useContext(UserContext);
    const [usuario, setDataUser] = useState([]);
    const [fileD, setfileD] = useState([]);
    const [tipodoc, settipodoc] = useState("");
    const [cuenta, setcuenta] = useState("");
    const [abriricuentas, setabriricuentas] = useState(false);

    const obtenerPaiss = async (coincidencia) => {
        try {


            let dt = JSON.parse(user)
            let response = await fetch(props.servidor + '/Paisapi/' + coincidencia + '?llave=' + dt.usuario.llaveempresa + '');

            if (response.ok) {
                let data = await response.json()

            }
        } catch (error) {
            console.log(error)
        }
    }
    useEffect(() => {
        let dt = JSON.parse(user)
        setDataUser(dt)
        // obtenerPaiss('');
    }, [])
    const convertBlobToBase64 = async (blob) => { // blob data
        return await blobToBase64(blob);
    }
    const blobToBase64 = blob => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });
    const recibirelemento = (event) => {
        const file = event.target.files[0];
        const reader = new FileReader();

        reader.onload = async (e) => {
            const fileData = e.target.result;
            const blob = new Blob([fileData], { type: file.type });
            var base64data = ""
            base64data = await convertBlobToBase64(blob);
            setfileD(base64data)
        };

        reader.readAsArrayBuffer(file);
        // var blob = doc.output('blob')
        // var reader = new FileReader();
        // var base64data = ""
        // base64data = await convertBlobToBase64(blob);
        // console.log(base64data)
        // var pdf = {
        //     pdfbase64: base64data

        // }

    }


    const guardarCambios = async () => {
        try {

            var xml = {
                archivo: fileD
            }
            let response;
            response = await fetch("" + props.servidor + "/EnvioApi/importar?llave=" + usuario.usuario.llaveempresa + "", {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json;charset=utf-8'
                },
                body: JSON.stringify(xml)
            })



            if (response.ok) {

                Swal.fire(
                    'Guardado Exitosamente!',
                    'El Pais fue guardado exitosamente',
                    'success'
                )
            } else {
                var textoerrores = await response.text()
                var jsonerrores = JSON.parse(textoerrores)
                var errores = jsonerrores.errors
                let claves = Object.keys(errores);
                var stringerrores = ""
                for (let i = 0; i < claves.length; i++) {
                    let clave = claves[i];
                    if (errores[clave].length != 0) {
                        stringerrores = stringerrores + errores[clave][0] + " \n "
                    }

                }
                var mensaje = stringerrores.replace(/The /g, '').replace(/field /g, '').replace(/is /g, 'es ').replace(/required/g, 'requerido')
                if (mensaje.includes('not be converted to System.Decimal')) {
                    mensaje = 'Hay campos numericos rellenados con letras'
                }
                console.log(stringerrores)
                Swal.fire(
                    'Error al Guardar!',
                    mensaje,
                    'error'
                )
            }
        } catch (error) {
            Swal.fire(
                'Error al Guardar!',
                error,
                'error'
            )
        }

    }


    const importarrecaudos = async (cuenta) => {
        try {
            if (cuenta == "") {
                Swal.fire(
                    'Error!',
                    'Debe seleccionar una Cuenta',
                    'error'
                )
                return
            }
            var xml = {
                archivo: fileD
            }
            let response;
            response = await fetch("" + props.servidor + "/caclientesapi/importarrecaudos/" + cuenta + "?llave=" + usuario.usuario.llaveempresa + "", {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json;charset=utf-8'
                },
                body: JSON.stringify(xml)
            })



            if (response.ok) {

                Swal.fire(
                    'Guardado Exitosamente!',
                    'Los Terceros fueron guardados exitosamente',
                    'success'
                )
            } else {
                var textoerrores = await response.text()
                var jsonerrores = JSON.parse(textoerrores)
                var errores = jsonerrores.errors
                let claves = Object.keys(errores);
                var stringerrores = ""
                for (let i = 0; i < claves.length; i++) {
                    let clave = claves[i];
                    if (errores[clave].length != 0) {
                        stringerrores = stringerrores + errores[clave][0] + " \n "
                    }

                }
                var mensaje = stringerrores.replace(/The /g, '').replace(/field /g, '').replace(/is /g, 'es ').replace(/required/g, 'requerido')
                if (mensaje.includes('not be converted to System.Decimal')) {
                    mensaje = 'Hay campos numericos rellenados con letras'
                }
                console.log(stringerrores)
                Swal.fire(
                    'Error al Guardar!',
                    mensaje,
                    'error'
                )
            }
        } catch (error) {
            Swal.fire(
                'Error al Guardar!',
                error,
                'error'
            )
        }

    }



    const montarcargofijos = async () => {
        try {

            var xml = {
                archivo: fileD
            }
            let response;
            response = await fetch("" + props.servidor + "/caclientesapi/montarcargofijos?llave=" + usuario.usuario.llaveempresa + "", {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json;charset=utf-8'
                },
                body: JSON.stringify(xml)
            })



            if (response.ok) {

                Swal.fire(
                    'Guardado Exitosamente!',
                    'Los Cargos Fijos fueron guardados exitosamente',
                    'success'
                )
            } else {
                var textoerrores = await response.text()
                var jsonerrores = JSON.parse(textoerrores)
                Swal.fire(
                    'Error al Guardar!',
                    jsonerrores,
                    'error'
                )
            }
        } catch (error) {
            Swal.fire(
                'Error al Guardar!',
                error,
                'error'
            )
        }

    }


    const guardarCambiosterceros = async () => {
        try {

            var xml = {
                archivo: fileD
            }
            let response;
            response = await fetch("" + props.servidor + "/caclientesapi/montarterceros?llave=" + usuario.usuario.llaveempresa + "", {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json;charset=utf-8'
                },
                body: JSON.stringify(xml)
            })



            if (response.ok) {

                Swal.fire(
                    'Guardado Exitosamente!',
                    'Los Terceros fueron guardados exitosamente',
                    'success'
                )
            } else {
                var textoerrores = await response.text()
                var jsonerrores = JSON.parse(textoerrores)
                var errores = jsonerrores.errors
                let claves = Object.keys(errores);
                var stringerrores = ""
                for (let i = 0; i < claves.length; i++) {
                    let clave = claves[i];
                    if (errores[clave].length != 0) {
                        stringerrores = stringerrores + errores[clave][0] + " \n "
                    }

                }
                var mensaje = stringerrores.replace(/The /g, '').replace(/field /g, '').replace(/is /g, 'es ').replace(/required/g, 'requerido')
                if (mensaje.includes('not be converted to System.Decimal')) {
                    mensaje = 'Hay campos numericos rellenados con letras'
                }
                console.log(stringerrores)
                Swal.fire(
                    'Error al Guardar!',
                    mensaje,
                    'error'
                )
            }
        } catch (error) {
            Swal.fire(
                'Error al Guardar!',
                error,
                'error'
            )
        }

    }

    const guardarCambioslecturas = async () => {
        try {

            var xml = {
                archivo: fileD
            }
            let response;
            response = await fetch("" + props.servidor + "/caclientesapi/montarlecturas?llave=" + usuario.usuario.llaveempresa + "", {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json;charset=utf-8'
                },
                body: JSON.stringify(xml)
            })



            if (response.ok) {

                Swal.fire(
                    'Guardado Exitosamente!',
                    'Las Lecturas fueron guardados exitosamente',
                    'success'
                )
            } else {
                var textoerrores = await response.text()

                Swal.fire(
                    'Error al Guardar!',
                    textoerrores,
                    'error'
                )
            }
        } catch (error) {
            Swal.fire(
                'Error al Guardar!',
                error,
                'error'
            )
        }

    }

    const comprardordeterceros = () => {

        // let options = { year: 'numeric', month: '2-digit', day: '2-digit' };

        // let _fechaInicio = fechaInicio.toLocaleDateString('es-PE', options)
        // let _fechaFin = fechaFin.toLocaleDateString('es-PE', options)
        let dt = JSON.parse(user)
        var coincidencia = ''

        var xml = {
            archivo: fileD
        }
        var emp = dt.empresa;

        const api = fetch("" + props.servidor + "/caclientesapi/comprardordeterceros?llave=" + usuario.usuario.llaveempresa + "", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json;charset=utf-8'
            },
            body: JSON.stringify(xml)
        })
            .then((response) => {
                return response.ok ? response.json() : Promise.reject(response);
            })
            .then((dataJson) => {
                var data = dataJson;
                const bytes = atob(data.pdf);

                // Crear un arreglo de bytes a partir de la cadena decodificada
                const byteArray = new Uint8Array(bytes.length);
                for (let i = 0; i < bytes.length; i++) {
                    byteArray[i] = bytes.charCodeAt(i);
                }

                // Crear un Blob a partir del arreglo de bytes
                const blob = new Blob([byteArray], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                // Crear una URL para el Blob
                const url = window.URL.createObjectURL(blob);
                // Crear un enlace para descargar el archivo PDF
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'Clientes_Inexistentes.xlsx');
                // Simular clic en el enlace para iniciar la descarga
                document.body.appendChild(link);
                link.click();
                // Liberar el objeto URL creado
                window.URL.revokeObjectURL(url);

            }).catch((error) => {
                console.log(error)
                Swal.fire(
                    'Opps!',
                    'No se pudo comparar la información',
                    'error'
                )
            })
    }
    const actualizarcoreros = async () => {
        try {

            var xml = {
                archivo: fileD
            }
            let response;
            response = await fetch("" + props.servidor + "/caclientesapi/actualizarcorreos?llave=" + usuario.usuario.llaveempresa + "", {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json;charset=utf-8'
                },
                body: JSON.stringify(xml)
            })



            if (response.ok) {

                Swal.fire(
                    'Guardado Exitosamente!',
                    'Los Emails fueron guardados exitosamente',
                    'success'
                )
            } else {
                var textoerrores = await response.text()

                Swal.fire(
                    'Error al Guardar!',
                    textoerrores,
                    'error'
                )
            }
        } catch (error) {
            Swal.fire(
                'Error al Guardar!',
                error,
                'error'
            )
        }

    }
    
    const actualizarlistadeprecio = async () => {
        try {

            var xml = {
                archivo: fileD
            }
            let response;
            response = await fetch("" + props.servidor + "/caclientesapi/actualizarlistadeprecio?llave=" + usuario.usuario.llaveempresa + "", {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json;charset=utf-8'
                },
                body: JSON.stringify(xml)
            })



            if (response.ok) {

                Swal.fire(
                    'Guardado Exitosamente!',
                    'Los Terceros fueron guardados exitosamente',
                    'success'
                )
            } else {
                var textoerrores = await response.text()
                var jsonerrores = JSON.parse(textoerrores)
                var errores = jsonerrores.errors
                let claves = Object.keys(errores);
                var stringerrores = ""
                for (let i = 0; i < claves.length; i++) {
                    let clave = claves[i];
                    if (errores[clave].length != 0) {
                        stringerrores = stringerrores + errores[clave][0] + " \n "
                    }

                }
                var mensaje = stringerrores.replace(/The /g, '').replace(/field /g, '').replace(/is /g, 'es ').replace(/required/g, 'requerido')
                if (mensaje.includes('not be converted to System.Decimal')) {
                    mensaje = 'Hay campos numericos rellenados con letras'
                }
                console.log(stringerrores)
                Swal.fire(
                    'Error al Guardar!',
                    mensaje,
                    'error'
                )
            }
        } catch (error) {
            Swal.fire(
                'Error al Guardar!',
                error,
                'error'
            )
        }

    }
    const guardarmontarprecios = async () => {
        try {

            var xml = {
                archivo: fileD
            }
            let response;
            response = await fetch("" + props.servidor + "/caclientesapi/montarprecios?llave=" + usuario.usuario.llaveempresa + "", {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json;charset=utf-8'
                },
                body: JSON.stringify(xml)
            })



            if (response.ok) {

                Swal.fire(
                    'Guardado Exitosamente!',
                    'Los Terceros fueron guardados exitosamente',
                    'success'
                )
            } else {
                var textoerrores = await response.text()
                var jsonerrores = JSON.parse(textoerrores)
                var errores = jsonerrores.errors
                let claves = Object.keys(errores);
                var stringerrores = ""
                for (let i = 0; i < claves.length; i++) {
                    let clave = claves[i];
                    if (errores[clave].length != 0) {
                        stringerrores = stringerrores + errores[clave][0] + " \n "
                    }

                }
                var mensaje = stringerrores.replace(/The /g, '').replace(/field /g, '').replace(/is /g, 'es ').replace(/required/g, 'requerido')
                if (mensaje.includes('not be converted to System.Decimal')) {
                    mensaje = 'Hay campos numericos rellenados con letras'
                }
                console.log(stringerrores)
                Swal.fire(
                    'Error al Guardar!',
                    mensaje,
                    'error'
                )
            }
        } catch (error) {
            Swal.fire(
                'Error al Guardar!',
                error,
                'error'
            )
        }

    }


    const guardarmontarcontabilidadgsalud = async () => {
        try {

            var xml = {
                archivo: fileD
            }
            let response;
            response = await fetch("" + props.servidor + "/ContabilidadApi/importarcontabilidadgsalud?llave=" + usuario.usuario.llaveempresa + "", {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json;charset=utf-8'
                },
                body: JSON.stringify(xml)
            })



            if (response.ok) {

                Swal.fire(
                    'Guardado Exitosamente!',
                    'Los Terceros fueron guardados exitosamente',
                    'success'
                )
            } else {
                var textoerrores = await response.text()
                var jsonerrores = JSON.parse(textoerrores)
                var errores = jsonerrores.errors
                let claves = Object.keys(errores);
                var stringerrores = ""
                for (let i = 0; i < claves.length; i++) {
                    let clave = claves[i];
                    if (errores[clave].length != 0) {
                        stringerrores = stringerrores + errores[clave][0] + " \n "
                    }

                }
                var mensaje = stringerrores.replace(/The /g, '').replace(/field /g, '').replace(/is /g, 'es ').replace(/required/g, 'requerido')
                if (mensaje.includes('not be converted to System.Decimal')) {
                    mensaje = 'Hay campos numericos rellenados con letras'
                }
                console.log(stringerrores)
                Swal.fire(
                    'Error al Guardar!',
                    mensaje,
                    'error'
                )
            }
        } catch (error) {
            Swal.fire(
                'Error al Guardar!',
                error,
                'error'
            )
        }

    }


    const guardarmontarfacturagsalud = async (tipodoc) => {
        try {

            var xml = {
                archivo: fileD
            }
            let response;
            response = await fetch("" + props.servidor + "/ContabilidadApi/importarfacturasgsalud/" + tipodoc + "?llave=" + usuario.usuario.llaveempresa + "", {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json;charset=utf-8'
                },
                body: JSON.stringify(xml)
            })



            if (response.ok) {

                Swal.fire(
                    'Guardado Exitosamente!',
                    'Los Terceros fueron guardados exitosamente',
                    'success'
                )
            } else {
                var textoerrores = await response.text()
                var jsonerrores = JSON.parse(textoerrores)
                var errores = jsonerrores.errors
                let claves = Object.keys(errores);
                var stringerrores = ""
                for (let i = 0; i < claves.length; i++) {
                    let clave = claves[i];
                    if (errores[clave].length != 0) {
                        stringerrores = stringerrores + errores[clave][0] + " \n "
                    }

                }
                var mensaje = stringerrores.replace(/The /g, '').replace(/field /g, '').replace(/is /g, 'es ').replace(/required/g, 'requerido')
                if (mensaje.includes('not be converted to System.Decimal')) {
                    mensaje = 'Hay campos numericos rellenados con letras'
                }
                console.log(stringerrores)
                Swal.fire(
                    'Error al Guardar!',
                    mensaje,
                    'error'
                )
            }
        } catch (error) {
            Swal.fire(
                'Error al Guardar!',
                error,
                'error'
            )
        }

    }


    const guardarreferenciassencillo = async (tipodoc) => {
        try {

            var xml = {
                archivo: fileD
            }
            let response;
            response = await fetch("" + props.servidor + "/ContabilidadApi/importarreferenciasencillo?llave=" + usuario.usuario.llaveempresa + "", {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json;charset=utf-8'
                },
                body: JSON.stringify(xml)
            })



            if (response.ok) {

                Swal.fire(
                    'Guardado Exitosamente!',
                    'Los Terceros fueron guardados exitosamente',
                    'success'
                )
            } else {
                var textoerrores = await response.text()
                var jsonerrores = JSON.parse(textoerrores)
                var errores = jsonerrores.errors
                let claves = Object.keys(errores);
                var stringerrores = ""
                for (let i = 0; i < claves.length; i++) {
                    let clave = claves[i];
                    if (errores[clave].length != 0) {
                        stringerrores = stringerrores + errores[clave][0] + " \n "
                    }

                }
                var mensaje = stringerrores.replace(/The /g, '').replace(/field /g, '').replace(/is /g, 'es ').replace(/required/g, 'requerido')
                if (mensaje.includes('not be converted to System.Decimal')) {
                    mensaje = 'Hay campos numericos rellenados con letras'
                }
                console.log(stringerrores)
                Swal.fire(
                    'Error al Guardar!',
                    mensaje,
                    'error'
                )
            }
        } catch (error) {
            Swal.fire(
                'Error al Guardar!',
                error,
                'error'
            )
        }

    }

    const guardarCambiosinventariofisico = async () => {
        try {

            var xml = {
                archivo: fileD
            }
            let response;
            response = await fetch("" + props.servidor + "/EnvioApi/importarinventariofisico?llave=" + usuario.usuario.llaveempresa + "", {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json;charset=utf-8'
                },
                body: JSON.stringify(xml)
            })



            if (response.ok) {

                Swal.fire(
                    'Guardado Exitosamente!',
                    'El Pais fue guardado exitosamente',
                    'success'
                )
            } else {
                var textoerrores = await response.text()
                var jsonerrores = JSON.parse(textoerrores)
                var errores = jsonerrores.errors
                let claves = Object.keys(errores);
                var stringerrores = ""
                for (let i = 0; i < claves.length; i++) {
                    let clave = claves[i];
                    if (errores[clave].length != 0) {
                        stringerrores = stringerrores + errores[clave][0] + " \n "
                    }

                }
                var mensaje = stringerrores.replace(/The /g, '').replace(/field /g, '').replace(/is /g, 'es ').replace(/required/g, 'requerido')
                if (mensaje.includes('not be converted to System.Decimal')) {
                    mensaje = 'Hay campos numericos rellenados con letras'
                }
                console.log(stringerrores)
                Swal.fire(
                    'Error al Guardar!',
                    mensaje,
                    'error'
                )
            }
        } catch (error) {
            Swal.fire(
                'Error al Guardar!',
                error,
                'error'
            )
        }

    }

    const pruebaapi = async () => {
        try {
            const modelo2 = {
                faencmovi: {
                    compañia: "",
                    concepto: "FE1",
                    ndocumento: 55,
                    fecha: "2024-02-07",
                    tercero: "cliente",
                    cliente: "1140900262",
                    nombrecli: "Miguel Julio",
                    observacion: "",
                    bruto: 1000,
                    iva: 0,
                    descuento: 0,
                    despiefac: 500,
                    retefuente: 0,
                    reteiva: 0,
                    ica: 0,
                    retefte: 0,
                    impconsumo: 0,
                    subtotal: 500,
                    total: 500,
                    fechpost: "2024-02-07",
                    fpago: "contado",
                    mpago: "transferencia",
                    sucursal: "",
                    cufe: "",
                    emitido: "no",
                    qr: "",
                },
                famovimiento: [
                    {
                        item: 1,
                        referencia: "1001",
                        descrip: ":",
                        bodega: "1",
                        cantidad: 1,
                        precio: 1000,
                        pordescuento: 0,
                        poriva: 0,
                        porimptoconsumo: 0,
                        subtotal: 1000,
                        compañia: "",
                        concepto: "FE1",
                        nrodocumento: 55,
                        costo: 0,
                        desadicional: "",

                        tercero: "cliente",
                        cliente: "1140900262",

                    }
                ],
                cacliente: {
                    tercero: "cliente",
                    clase: "13",
                    codigo: "1140900262",
                    cedula: "1140900262",
                    digito: "",
                    razsoc: "miguel julio",
                    codigomun: "001",
                    coddepar: "08",
                    codpais: "CO",
                    email: "miguelantoiojuliodiaz@gmail.com",
                    direccion: "cra 1 # 2-3",
                    regtrib: "R-99-PN",
                    resfis: "R-99",
                }

            }

            var response = await fetch(props.servidor + "/EnvioApi/72175003/TxTuXgXVAp/ecaf49b6-b270-4cf2-94ec-7b126bda5c81?llave=MSMFCECCPCLEDPFVTPFKKMFQ72175003APHIGHAMOIXQXPJOEHSHHONHCAHUKZYSFO", {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json;charset=utf-8'
                },
                body: JSON.stringify(modelo2)
            })

            if (response.ok) {

                Swal.fire(
                    'Guardado Exitosamente!',
                    'El Pais fue guardado exitosamente',
                    'success'
                )
            } else {
                var textoerrores = await response.text()

                Swal.fire(
                    'Error al Guardar!',
                    textoerrores,
                    'error'
                )
            }
        } catch (error) {
            Swal.fire(
                'Error al Guardar!',
                error,
                'error'
            )
        }

    }
    const handleChangegrillafaencmovi = async (event, value, name, codi) => {


        var vll = ''
        if (value) {
            vll = value[codi]
        }
        setcuenta(vll)
    }
    return (
        <>
            <Card>
                <CardBody>
                    <Row>
                        <Col sm={12}>
                            <Card>
                                <CardHeader style={{ backgroundColor: '#188BAD', color: "white", textAlign: 'center' }}>
                                    Importar Datos
                                </CardHeader>
                                <CardBody>
                                    <Row>
                                        <Col sm={6}>
                                            <Input type="file" onChange={(event) => { recibirelemento(event) }} style={{ backgroundColor: '#188BAD', color: "white" }} />
                                        </Col>
                                        <Col sm={6}>
                                            <Input placeholder="Tipo de Documento" onChange={(e) => { settipodoc(e.target.value) }} style={{ marginBottom: '10px' }} />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col sm={6}>
                                            <Button style={{ backgroundColor: '#188BAD', color: "white", margin: '5px', width: '100%' }} onClick={() => { guardarCambios() }}>Importar</Button>
                                            <Button style={{ backgroundColor: '#188BAD', color: "white", margin: '5px', width: '100%' }} onClick={() => { guardarCambiosinventariofisico() }}>Inventario Fisico</Button>
                                            <Button style={{ backgroundColor: '#188BAD', color: "white", margin: '5px', width: '100%' }} onClick={() => { guardarmontarcontabilidadgsalud() }}>Contabilidad GSalud</Button>
                                            <Button style={{ backgroundColor: '#188BAD', color: "white", margin: '5px', width: '100%' }} onClick={() => { actualizarcoreros(tipodoc) }}>Actualizar Correos</Button>
                                            {/* <Button style={{ backgroundColor: '#188BAD', color: "white", margin: '5px', width: '100%' }} onClick={() => { guardarmontarprecios() }}>Actualizar Tarifas</Button> */}
                                            {/* <Button style={{ backgroundColor: '#188BAD', color: "white", margin: '5px', width: '100%' }} onClick={() => { actualizarlistadeprecio() }}>Actualizar Cargo Fijo - Descuentos</Button> */}
                                            
                                            <Button style={{ backgroundColor: '#188BAD', color: "white", margin: '5px', width: '100%' }} onClick={() => { guardarCambiosterceros() }}>Importar Terceros</Button>
                                            {/* <Button style={{ backgroundColor: '#188BAD', color: "white", margin: '5px', width: '100%' }} onClick={() => { guardarCambioslecturas() }}>Importar Lecturas</Button> */}
                                            {/* <Button style={{ backgroundColor: '#188BAD', color: "white", margin: '5px', width: '100%' }} onClick={() => { montarcargofijos() }}>Importar Cargos Fijos</Button> */}
                                        </Col>
                                        <Col sm={6}>
                                        
                                        <Button style={{ backgroundColor: '#188BAD', color: "white", margin: '5px', width: '100%' }} onClick={() => { guardarreferenciassencillo(tipodoc) }}>Articulos GSalud</Button>
                                            <Button style={{ backgroundColor: '#188BAD', color: "white", margin: '5px', width: '100%' }} onClick={() => { comprardordeterceros(tipodoc) }}>Comparar Terceros</Button>
                                            <Button style={{ backgroundColor: '#188BAD', color: "white", margin: '5px', width: '100%' }} onClick={() => { guardarmontarfacturagsalud(tipodoc) }}>Factura GSalud</Button>
                                            <Input placeholder="Cuenta" value={cuenta} onKeyUp={() => {
                                                setabriricuentas(true)
                                            }} style={{ marginBottom: '10px' }} />
                                            {/* <Button style={{ backgroundColor: '#188BAD', color: "white", margin: '5px', width: '100%' }} onClick={() => { importarrecaudos(cuenta) }}>Importar Recaudos</Button> */}


                                        </Col>
                                    </Row>
                                    <Row>


                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </CardBody>

            </Card>
            <BuscarCuentas tituloencabezado={"Buscar Cuentas"} abrir={abriricuentas} servidor={props.servidor} setabrirbanco={(ab) => { setabriricuentas(ab) }} seleccionar={(value) => {
                handleChangegrillafaencmovi('', value, 'codcuenta', 'codcuenta')
                setabriricuentas(false)
            }} />




        </>
    )
}
export default ImportarExportar;
