import React, { useContext } from 'react'
import { Navigate, Outlet } from 'react-router-dom'
import NavBar from './componentes/NavBar'
import { Link } from 'react-router-dom';
import { UserContext } from './context/UserProvider';
import Swal from 'sweetalert2'
import { useState } from 'react';
import Tabs from 'react-bootstrap/Tabs'
import Tab from 'react-bootstrap/Tab'
import Producto from './views/Producto';
import Usuario from './views/Usuario';
import CrearTercero from './views/CrearTercero';
import Backup from './views/Backup';
import CrearTerceroAdicionales from './views/CrearTerceroAdicionales';
import CrearTercero2 from './views/CrearTercero copy';
import RingCallRecepcion from './views/RingCallRecepcion';
import Empresa from './views/Empresa';
import Categoria from './views/Categoria';
import Grupo from './views/Grupo';
import Permisos from './views/Permisos';
import DashBoard from './views/DashBoard';
import DashBoardAdministracion from './views/DashBoardAdministracion';
import HistorialVenta from './views/HistorialVenta';
import Reportes from './views/Reportes';
import Reportes2 from './views/Reportes2';
import Inicio from './views/Inicio';
import Reconstruir from './views/Reconstruir';
import NotFound from './views/NotFound';
import { Card, CardBody, CardHeader, Col, FormGroup, Input, InputGroup, InputGroupText, Label, Row, Table, Button, Container, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
// import ReporteVenta from './views/ReporteVenta';
import ReporteVenta from './views/Reportes/Inventario/ReporteExistenciaArticulos';
import Venta from './views/Venta';
import Ventadrogueria from './views/Ventadrogueria';
import Sucursal from './views/Sucursal';
import CrearVendedor from './views/CrearVendedor';
import CrearPais from './views/CrearPais';
import CrearMunicipio from './views/CrearMunicipio';
import CrearDepartamento from './views/CrearDepartamento';
import CrearListadePrecio from './views/CrearListadePrecio';
import CrearCentrodeCosto from './views/CrearCentrodeCosto';
import CrearBodega from './views/CrearBodega';
import CrearTipoDocumento from './views/CrearTipoDocumento';
import Chat from './views/Chat';
import CrearMediodePagoDian from './views/CrearMediodePagoDian';
import CrearMediodePago from './views/CrearMediodePago';
import MaestrosNomina from './views/MaestrosNomina';
import PrestacionesSocialesNomina from './views/PrestacionesSocialesNomina';
import ProcesosNomina from './views/ProcesosNomina';
import DatosBasicosContabilidad from './views/DatosBasicosContabilidad';
import Contperiodo from './views/Contperiodo';
import Plandecuentas from './views/Plandecuentas';
import ConciliacionBancaria from './views/ConciliacionBancaria';
import ProcesosContabilidad from './views/ProcesosContabilidad';
import Ctabonos from './views/Cartera/Ctabonos';
import CierreCaja from './views/CierreCaja';
import CierreCajaRestaurante from './views/CierreCajaRestaurante';
import Clasiarticulos from './views/Clasiarticulos';
import Conclasiarticulos from './views/Conclasiarticulos';
import Clasiterceros from './views/Clasiterceros';
import Conclasiterceros from './views/Conclasiterceros';
import Ctcomprobantes from './views/Ctcomprobantes';
import Contabilidad from './views/Contabilidad';
import Tesoreria from './views/Tesoreria';
import Banco from './views/Banco';
import Correo from './views/Correo';
import Radian from './views/Radian';
import GasParametrosFactura from './views/GasParametrosFactura';
import VentaGasBloqueActualizar from './views/VentaGasBloqueActualizar';
import Icono from './views/images/logo2.png'

import ConCorreo from './views/ConCorreo';
import ModelosContables from './views/ModelosContables';
import Importarexportar from './views/Importarexportar';
import ReportesGraf from './views/ReportesGraf';
import DashBoardinicio from './views/DashBoard';
import PlantillaCont from './views/PlantillaCont';
import InventarioFisico from './views/InventarioFisico';
import RestauranteOrdenDomicilio from './views/Restaurante/OrdenDomicilio';
import RestauranteOrdenOrdenes from './views/Restaurante/Ordenes/Orders';
import RestauranteOrdenOrdenespormesero from './views/Restaurante/PorMesero/Orders';

import RestauranteOrdenMesas from './views/Restaurante/Mesas/Mesasj';
import Cierreanual from './views/cierreanual';
import EnvioBloqueDian from './views/EnvioBloqueDian';
import Bonos from './views/Bonos';
import TercerosGas from './views/TercerosGas';
import VentaGasBloque from './views/VentaGasBloque';
import ModeloContableArticuloCCosto from './views/ModeloContableArticuloCCosto';
import CruceAnticiposCartera from './views/Cartera/CruceAnticiposCartera';
import CruceAnticiposTesoreria from './views/CruceAnticiposTesoreria';
import Turnos from './views/Turnos';

import Conplantillainforme from './views/ConfiguracionReportes/Conplantillainforme';
import { HubConnection, HubConnectionBuilder, LogLevel, HttpTransportType } from "@microsoft/signalr";

const modelo = {
    nombre: "",
    correo: "",
    idRolNavigation: {
        idRol: 0,
        descripcion: ""
    }
}

const App = (props) => {
    const { user, cerrarSession } = useContext(UserContext)
    const [key, setKey] = React.useState('DASHBOARD');
    const [producto, setproducto] = React.useState(false);
    const [correo, setcorreo] = React.useState(false);
    const [concorreo, setconcorreo] = React.useState(false);
    const [radian, setradian] = React.useState(false);
    const [usuario, setusuario] = React.useState(false);
    const [usuariounico, setusuariounico] = React.useState(false);
    const [categoria, setcategoria] = React.useState(false);
    const [dashboard, setdashboard] = React.useState(false);
    const [historialventa, sethistorialventa] = React.useState(false);
    const [inicio, setinicio] = React.useState(false);
    const [reporteVenta, setreporteVenta] = React.useState(false);
    const [venta, setventa] = React.useState(false);
    const [ventadrogueria, setventadrogueria] = React.useState(false);
    const [grupo, setgrupo] = React.useState(false);
    const [permisosdetallado, setpermisosdetallado] = React.useState(false);
    const [empresa, setempresa] = React.useState(false);
    const [ringcallrecepcion, setringcallrecepcion] = React.useState(false);
    const [creartercero, setcreartercero] = React.useState(false);
    const [crearvendedor, setcrearvendedor] = React.useState(false);
    const [crearpais, setcrearpais] = React.useState(false);
    const [crearmunicipio, setcrearmunicipio] = React.useState(false);
    const [creardepartamento, setcreardepartamento] = React.useState(false);
    const [crearlistadeprecio, setcrearlistadeprecio] = React.useState(false);
    const [crearcentrodecosto, setcrearcentrodecosto] = React.useState(false);
    const [reconstruircontabilidad, setreconstruircontabilidad] = React.useState(false);
    const [pantillacont, setpantillacont] = React.useState(false);
    const [inventariofisico, setinventariofisico] = React.useState(false);
    const [ventagasbloque, setventagasbloque] = React.useState(false);
    const [modeloarticuloccosto, setmodeloarticuloccosto] = React.useState(false);
    const [ventagasbloqueactualizar, setventagasbloqueactualizar] = React.useState(false);

    const [crearbodega, setcrearbodega] = React.useState(false);
    const [creartipodocumento, setcreartipodocumento] = React.useState(false);
    const [chat, setchat] = React.useState(false);
    const [crearmediodepagodian, setcrearmediodepagodian] = React.useState(false);
    const [crearmediodepago, setcrearmediodepago] = React.useState(false);
    const [reportes, setreportes] = React.useState(false);
    const [maestrosnomina, setmaestrosnomina] = React.useState(false);
    const [prestacionessocialesnomina, setprestacionessocialesnomina] = React.useState(false);
    const [procesosnomina, setprocesosnomina] = React.useState(false);
    const [datosbasicoscontabilidad, setdatosbasicoscontabilidad] = React.useState(false);
    const [periodoscontabilidad, setperiodoscontabilidad] = React.useState(false);
    const [plandecuentas, setplandecuentas] = React.useState(false);
    const [procesoscontabilidad, setprocesoscontabilidad] = React.useState(false);
    const [ctabonos, setctabonos] = React.useState(false);
    const [cruceanticipo, setcruceanticipo] = React.useState(false);
    const [cruceanticipotesoreria, setcruceanticipotesoreria] = React.useState(false);
    const [seleccionarcaja, setseleccionarcaja] = React.useState(true);
    const [cajaseleccionada, setcajaseleccionada] = React.useState("001");
    const [cajasbusqueda, setcajasbusqueda] = React.useState([]);
    const [cierrecaja, setcierrecaja] = React.useState(false);
    const [cierrecajarestaurante, setcierrecajarestaurante] = React.useState(false);
    const [clasiarticulos, setclasiarticulos] = React.useState(false);
    const [conclasiarticulos, setconclasiarticulos] = React.useState(false);
    // const [conciliacion, setconciliacion] = React.useState(false);
    const [clasiterceros, setclasiterceros] = React.useState(false);
    const [conclasiterceros, setconclasiterceros] = React.useState(false);
    const [comprobantescontabilidad, setcomprobantescontabilidad] = React.useState(false);
    const [contabilidad, setcontabilidad] = React.useState(false);
    const [abrircerrarperiodo, setabrircerrarperiodo] = React.useState(false);
    const [cierreanual, setcierreanual] = React.useState(false);
    const [cociliancionbancaria, setcociliancionbancaria] = React.useState(false);
    const [exogena, setexogena] = React.useState(false);
    const [tesoreria, settesoreria] = React.useState(false);
    const [banco, setbanco] = React.useState(false);
    const [sucursal, setsucursal] = React.useState(false);
    const [tercerosdatosadicionales, settercerosdatosadicionales] = React.useState(false);
    const [backup, setbackup] = React.useState(false);
    const [tercerosgas, settercerosgas] = React.useState(false);
    const [crucecartera, setcrucecartera] = React.useState(false);
    const [turnos, setturnos] = React.useState(false);

    const [modeloscontables, setmodeloscontables] = React.useState(false);
    const [importarexportar, setimportarexportar] = React.useState(false);
    const [permisosusuario, setpermisosusuario] = React.useState(false);
    const [conplantillainforme, setconplantillainforme] = React.useState(false);
    const [restauranteordendomicilio, setrestauranteordendomicilio] = React.useState(false);
    const [restauranteordenordenes, setrestauranteordenordenes] = React.useState(false);
    const [restauranteordenordenespormesero, setrestauranteordenordenespormesero] = React.useState(false);

    const [restauranteordenmesas, setrestauranteordenmesas] = React.useState(false);
    const [enviobloquedian, setenviobloquedian] = React.useState(false);
    const [bono, setbono] = React.useState(false);
    const [gasparametrofactura, setgasparametrofactura] = React.useState(false);
    const [mostrarlogo, setmostrarlogo] = React.useState(true);

    const connection = new HubConnectionBuilder()
        .withUrl(props.servidor + "/socket", {
            skipNegotiation: true,
            transport: HttpTransportType.WebSockets
        })
        .build();
    const [servidor, setservidor] = React.useState(props.servidor);
    const [servidorempresa, setservidorempresa] = React.useState(props.servidorempresa);
    const correrSocket = async () => {
        try {

            await connection.start();
            var tt = connection.state
        } catch (error) {

        }
    }
    const obtenerCajas = async () => {
        if (cajasbusqueda.length == 0) {
            try {


                let dt = JSON.parse(user)
                let response = await fetch(props.servidor + '/ConcajaApi/?llave=' + dt.usuario.llaveempresa + '');

                if (response.ok) {
                    let data = await response.json()
                    setcajasbusqueda(data)
                }
            } catch (error) {
                console.log(error)
            }
            obtenerpermisos()
        }
    }
    if (user == null) {
        return <Navigate to="/Login" />
    } else {
        if (cajasbusqueda.length == 0) {

            obtenerCajas()
            // if (connection.state == 'Disconnected') {
            //     correrSocket()
            // }
        }


    }
    const obtenerpermisos = async () => {
        if (cajasbusqueda.length == 0) {
            try {
                let dt = JSON.parse(user)
                var codigo = dt.usuario.cargo

                let response = await fetch(props.servidor + '/PermisosApi/unico/' + codigo + '?llave=' + dt.usuario.llaveempresa + '');

                if (response.ok) {
                    let data = await response.json()
                    setpermisosusuario(data)
                    var cajafi = ""
                    data.forEach(element => {
                        if (element.tipo == "cajafija") {
                            cajafi = element.tipodoc
                        }
                    });
                    if (cajafi == "") {
 
                    } else {
                        setcajaseleccionada(cajafi)

                    }
                }
            } catch (error) {
                console.log(error)
            }
        }
    }

    const mostrarModalSalir = () => {

        Swal.fire({
            title: 'Esta por salir',
            text: "Desea cerrar sesion?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Si, continuar',
            cancelButtonText: 'No, volver'
        }).then((result) => {
            if (result.isConfirmed) {
                cerrarSession()
            }
        })

    }


    return (
        <>

            <NavBar

                setmodeloarticuloccosto={(usuario) => {
                    setmodeloarticuloccosto(usuario)
                    setmostrarlogo(false)
                    setKey('Modelo Articulo Ccosto')
                }}
                setventagasbloque={(usuario) => {
                    setventagasbloque(usuario)
                    setmostrarlogo(false)
                    setKey('Ventas Gas')
                }}
                setturnos={(usuario) => {
                    setturnos(usuario)
                    setmostrarlogo(false)
                    setKey('Turnos')
                }}
                
                setventagasbloqueactualizar={(usuario) => {
                    setventagasbloqueactualizar(usuario)
                    setmostrarlogo(false)
                    setKey('Ventas Gas Actualizar')
                }}

                setbono={(usuario) => {
                    setbono(usuario)
                    setmostrarlogo(false)
                    setKey('Bonos')
                }}
                setgasparametrofactura={(usuario) => {
                    setgasparametrofactura(usuario)
                    setmostrarlogo(false)
                    setKey('Parametros Gas')
                }}

                settercerosgas={(usuario) => {
                    settercerosgas(usuario)
                    setmostrarlogo(false)
                    setKey('Terceros Gas')
                }}

                setenviobloquedian={(usuario) => {
                    setenviobloquedian(usuario)
                    setmostrarlogo(false)
                    setKey('Envio Bloque')
                }}
                setabrircerrarperiodo={(usuario) => {
                    setabrircerrarperiodo(usuario)
                    setmostrarlogo(false)
                    setKey('Abrir/Cerrar Periodo')
                }}
                setcierreanual={(usuario) => {
                    setcierreanual(usuario)
                    setmostrarlogo(false)
                    setKey('Cierre Anual')
                }}
                setcociliancionbancaria={(usuario) => {
                    setcociliancionbancaria(usuario)
                    setmostrarlogo(false)
                    setKey('Conciliacion Bancaria')
                }}
                setexogena={(usuario) => {
                    setexogena(usuario)
                    setmostrarlogo(false)
                    setKey('Exógena')
                }}
                setbackup={(usuario) => {
                    setbackup(usuario)
                    setmostrarlogo(false)
                    setKey('Backup')
                }}
                setconplantillainforme={(usuario) => {
                    setconplantillainforme(usuario)
                    setmostrarlogo(false)
                    setKey('Configuracion Plantilla')
                }}
                setrestauranteordendomicilio={(usuario) => {
                    setrestauranteordendomicilio(usuario)
                    setmostrarlogo(false)
                    setKey('Orden Domicilio')
                }}
                setrestauranteordenordenes={(usuario) => {
                    setrestauranteordenordenes(usuario)
                    setmostrarlogo(false)
                    setKey('Ordenes En Proceso')
                }}
                setrestauranteordenordenespormesero={(usuario) => {
                    setrestauranteordenordenespormesero(usuario)
                    setmostrarlogo(false)
                    setKey('Ordenes Mesero')
                }}
                setrestauranteordenmesas={(usuario) => {
                    setrestauranteordenmesas(usuario)
                    setmostrarlogo(false)
                    setKey('Mesas')
                }}

                setinventariofisico={(usuario) => {
                    setinventariofisico(usuario)
                    setmostrarlogo(false)
                    setKey('Inventario Fisico')
                }}

                setpantillacont={(usuario) => {
                    setpantillacont(usuario)
                    setmostrarlogo(false)
                    setKey('Plantilla Contable')
                }}

                setimportarexportar={(usuario) => {
                    setimportarexportar(usuario)
                    setmostrarlogo(false)
                    setKey('Importar/Exportar')
                }}
                setmodeloscontables={(usuario) => {
                    setmodeloscontables(usuario)
                    setmostrarlogo(false)
                    setKey('Modelos Contables')
                }}
                setcontabilidad={(usuario) => {
                    setcontabilidad(usuario)
                    setmostrarlogo(false)
                    setKey('Contabilidad')
                }}
                settesoreria={(usuario) => {
                    settesoreria(usuario)
                    setmostrarlogo(false)
                    setKey('Tesoreria')
                }}
                setbanco={(usuario) => {
                    setbanco(usuario)
                    setmostrarlogo(false)
                    setKey('Banco')
                }}
                setsucursal={(usuario) => {
                    setsucursal(usuario)
                    setmostrarlogo(false)
                    setKey('Sucursal')
                }}
                settercerosdatosadicionales={(usuario) => {
                    settercerosdatosadicionales(usuario)
                    setmostrarlogo(false)
                    setKey('Adicionales Tercero')
                }}

                setcorreo={(usuario) => {
                    setmostrarlogo(false)
                    setcorreo(usuario)
                    setKey('Correo')
                }}
                setconcorreo={(usuario) => {
                    setconcorreo(usuario)
                    setmostrarlogo(false)
                    setKey('Creacion Correo')
                }}
                setradian={(usuario) => {
                    setradian(usuario)
                    setmostrarlogo(false)
                    setKey('Radian')
                }}
                setclasiarticulos={(usuario) => {
                    setclasiarticulos(usuario)
                    setmostrarlogo(false)
                    setKey('Clasi Productos')
                }} setcomprobantescontabilidad={(usuario) => {
                    setcomprobantescontabilidad(usuario)
                    setmostrarlogo(false)
                    setKey('Comprobantes')
                }} setconclasiarticulos={(usuario) => {
                    setmostrarlogo(false)
                    setconclasiarticulos(usuario)
                    setKey('Nombres Clasi Productos')
                }}
                 setclasiterceros={(usuario) => {
                    setmostrarlogo(false)
                    setclasiterceros(usuario)
                    setKey('Clasi Terceros')
                }} setconclasiterceros={(usuario) => {
                    setmostrarlogo(false)
                    setconclasiterceros(usuario)
                    setKey('Nombres Clasi Terceros')
                }}
                setcierrecaja={(usuario) => {
                    setmostrarlogo(false)
                    setcierrecaja(usuario)
                    setKey('Cierre Caja')
                }}
                setcierrecajarestaurante={(usuario) => {
                    setcierrecajarestaurante(usuario)
                    setmostrarlogo(false)
                    setKey('Cierre Caja R')
                }}
                setusuario={(usuario) => {
                    setusuario(usuario)
                    setmostrarlogo(false)
                    setKey('Usuario')
                }}
                setusuariounico={(usuario) => {
                    setmostrarlogo(false)
                    setusuariounico(usuario)
                }}

                setmaestrosnomina={(usuario) => {
                    setmostrarlogo(false)
                    setmaestrosnomina(usuario)
                    setKey('Maestros Nomina')
                }} setprocesoscontabilidad={(usuario) => {
                    setmostrarlogo(false)
                    setprocesoscontabilidad(usuario)
                    setKey('Procesos Contabilidad')
                }} setdatosbasicoscontabilidad={(usuario) => {
                    setdatosbasicoscontabilidad(usuario)
                    setmostrarlogo(false)
                    setKey('Datos Basicos C')
                }} setperiodoscontabilidad={(usuario) => {
                    setperiodoscontabilidad(usuario)
                    setmostrarlogo(false)
                    setKey('Periodos')
                }} setplandecuentas={(usuario) => {
                    setmostrarlogo(false)
                    setplandecuentas(usuario)
                    setKey('Plan de Cuentas')
                }} setprestacionessocialesnomina={(usuario) => {
                    setprestacionessocialesnomina(usuario)
                    setmostrarlogo(false)
                    setKey('Prestaciones Sociales')
                }} setprocesosnomina={(usuario) => {
                    setprocesosnomina(usuario)
                    setmostrarlogo(false)
                    setKey('Procesos Nomina')
                }} setproducto={(producto) => {
                    setproducto(producto)
                    setmostrarlogo(false)
                    setKey('Articulos')

                }} setcreartercero={(creartercero) => {
                    setcreartercero(creartercero)
                    setmostrarlogo(false)
                    setKey('Terceros')

                }}
                setcrearbodega={(crearbodega) => {
                    setcrearbodega(crearbodega)
                    setmostrarlogo(false)
                    setKey('Bodegas')

                }}

                setchat={(chat) => {
                    setmostrarlogo(false)
                    setchat(chat)
                    setKey('Chat')

                }}
                setcrearlistadeprecio={(crearlistadeprecio) => {
                    setcrearlistadeprecio(crearlistadeprecio)
                    setmostrarlogo(false)
                    setKey('Lista de Precios')

                }}
                setcrearcentrodecosto={(crearcentrodecosto) => {
                    setcrearcentrodecosto(crearcentrodecosto)
                    setmostrarlogo(false)
                    setKey('Centros de Costo')

                }}
                setreconstruircontabilidad={(crearcentrodecosto) => {
                    setreconstruircontabilidad(crearcentrodecosto)
                    setmostrarlogo(false)
                    setKey('Reconstruir')

                }}
                setcrearpais={(crearpais) => {
                    console.log(servidor)
                    setcrearpais(crearpais)
                    setmostrarlogo(false)
                    setKey('Paises')

                }}
                setcrearmunicipio={(crearmunicipio) => {
                    setcrearmunicipio(crearmunicipio)
                    setmostrarlogo(false)
                    setKey('Municipios')

                }}
                setcreardepartamento={(creardepartamento) => {
                    setcreardepartamento(creardepartamento)
                    setmostrarlogo(false)
                    setKey('Departamentos')

                }}
                setcrearvendedor={(crearvendedor) => {
                    setcrearvendedor(crearvendedor)
                    setmostrarlogo(false)
                    setKey('Vendedores')

                }}
                setringcallrecepcion={(ringcallrecepcion) => {
                    setringcallrecepcion(ringcallrecepcion)
                    setmostrarlogo(false)
                    setKey('Ring Call Recepcion')

                }}
                setcategoria={(categoria) => {
                    setcategoria(categoria)
                    setmostrarlogo(false)
                    setKey('Categoria')

                }} setdashboard={(dashboard) => {
                    setdashboard(dashboard)
                    setmostrarlogo(false)
                    setKey('Historial')

                }} sethistorialventa={(historialventa) => {
                    sethistorialventa(historialventa)
                    setmostrarlogo(false)
                    setKey('Historial Venta')

                }} setreporteVenta={(reporteventa) => {
                    setreporteVenta(reporteventa)
                    setmostrarlogo(false)
                    setKey('Reporte Venta')

                }} setventa={(venta) => {
                    setventa(venta)
                    setmostrarlogo(false)
                    setKey('Movimientos')

                }} setventadrogueria={(ventadrogueria) => {
                    setventadrogueria(ventadrogueria)
                    setmostrarlogo(false)
                    setKey('Movimientos')

                }} setinicio={(inicio) => {
                    setinicio(inicio)
                    setmostrarlogo(false)
                    setKey('Inicio')

                }} setgrupo={(grupo) => {
                    setgrupo(grupo)
                    setmostrarlogo(false)
                    setKey('Grupo')

                }} setpermisosdetallado={(grupo) => {
                    setpermisosdetallado(grupo)
                    setmostrarlogo(false)
                    setKey('Permisos')

                }} setempresa={(empresa) => {
                    setempresa(empresa)
                    setmostrarlogo(false)
                    setKey('Empresa')

                }} setcreartipodocumento={(creartipodocumento) => {
                    setcreartipodocumento(creartipodocumento)
                    setmostrarlogo(false)
                    setKey('Tipos de Documento')

                }}
                setcrearmediodepagodian={(crearmediodepagodian) => {
                    setcrearmediodepagodian(crearmediodepagodian)
                    setmostrarlogo(false)
                    setKey('Medios de Pago Dian')

                }}
                setcrearmediodepago={(crearmediodepago) => {
                    setcrearmediodepago(crearmediodepago)
                    setmostrarlogo(false)
                    setKey('Medios de Pago')

                }}
                setreportes={(reportes) => {
                    setreportes(reportes)
                    setmostrarlogo(false)
                    setKey('Informes')

                }} setctabonos={(reportes) => {
                    setctabonos(reportes)
                    setmostrarlogo(false)
                    setKey('Cartera')

                }} setcruceanticipo={(reportes) => {
                    setmostrarlogo(false)
                    setcruceanticipo(reportes)
                    setKey('Cruce Cartera')

                }} setcruceanticipotesoreria={(reportes) => {
                    setmostrarlogo(false)
                    setcruceanticipotesoreria(reportes)
                    setKey('Cruce Cartera Proveedores')

                }} />

            {/*Content Wrapper*/}
            <div id="content-wrapper" className="d-flex flex-column">

                {/*Main Content*/}
                <div id="content">

                    <div className="container-fluid">

                        <Tabs
                            id="controlled-tab-example"
                            activeKey={key}
                            onSelect={(k) => setKey(k)}
                            className=" pestañas"
                        >
                            {/* {configuraciondian ?
                    <Tab eventKey="Configuraciones Dian" title="Configuraciones Dian">
                        <Configfacturas nitempresa={props.nitempresa}   cedula={props.cedula} usuario={props.usuario} llaveempresa={props.llaveempresa} nombreempresa={props.nombreempresa}nombreusuario={props.nombreusuario} llaveusuario={props.llaveusuario} url={url} />
                        
                    </Tab> : false} */}

                            {(false) &&
                                <Tab eventKey="DashBoard" title="DashBoard">
                                    <DashBoardAdministracion servidor={servidor} setimportarexportar={(reportes) => {
                                        setimportarexportar(reportes)
                                    }} />
                                </Tab>}

                            {(modeloarticuloccosto) &&
                                <Tab eventKey="Modelo Articulo Ccosto" title="Modelo Articulo Ccosto">
                                    <ModeloContableArticuloCCosto servidor={servidor} setpantillacont={(reportes) => {
                                        setmodeloarticuloccosto(reportes)
                                    }} />
                                </Tab>}
                               
                            {(ventagasbloque) &&
                                <Tab eventKey="Ventas Gas" title="Ventas Gas">
                                    <VentaGasBloque servidor={servidor} setpantillacont={(reportes) => {
                                        setventagasbloque(reportes)
                                    }} />
                                </Tab>}
                            {(ventagasbloqueactualizar) &&
                                <Tab eventKey="Ventas Gas Actualizar" title="Ventas Gas Actualizar">
                                    <VentaGasBloqueActualizar servidor={servidor} setpantillacont={(reportes) => {
                                        setventagasbloqueactualizar(reportes)
                                    }} />
                                </Tab>}

                            {(pantillacont) &&
                                <Tab eventKey="Plantilla Contable" title="Plantilla Contable">
                                    <PlantillaCont servidor={servidor} setpantillacont={(reportes) => {
                                        setpantillacont(reportes)
                                    }} />
                                </Tab>}
                            {(inventariofisico) &&
                                <Tab eventKey="Inventario Fisico" title="Inventario Fisico">
                                    <InventarioFisico permisosusuario={permisosusuario} servidor={servidor} setinventariofisico={(reportes) => {
                                        setinventariofisico(reportes)
                                    }} />
                                </Tab>}
                            {(conplantillainforme) &&
                                <Tab eventKey="Configuracion Plantilla" title="Configuracion Plantilla">
                                    <Conplantillainforme servidor={servidor} set={(reportes) => {
                                        setimportarexportar(reportes)
                                    }} />
                                </Tab>}
                            {(importarexportar) &&
                                <Tab eventKey="Importar/Exportar" title="Importar/Exportar">
                                    <Importarexportar servidor={servidor} setimportarexportar={(reportes) => {
                                        setimportarexportar(reportes)
                                    }} />
                                </Tab>}
                            {(enviobloquedian) &&
                                <Tab eventKey="Envio Bloque" title="Envio Bloque">
                                    <EnvioBloqueDian servidor={servidor} setenviobloquedian={(reportes) => {
                                        setenviobloquedian(reportes)
                                    }} />
                                </Tab>}
                            {(bono) &&
                                <Tab eventKey="Bonos" title="Bonos">
                                    <Bonos servidor={servidor} setbono={(reportes) => {
                                        setbono(reportes)
                                    }} />
                                </Tab>}
                            {(gasparametrofactura) &&
                                <Tab eventKey="Parametros Gas" title="Parametros Gas">
                                    <GasParametrosFactura servidor={servidor} setgasparametrofactura={(reportes) => {
                                        setgasparametrofactura(reportes)
                                    }} />
                                </Tab>}
                                {(turnos) &&
                                <Tab eventKey="Turnos" title="Turnos">
                                    <Turnos servidor={servidor} caja={cajaseleccionada}   permisosusuario={permisosusuario} setturnos={(reportes) => {
                                        setturnos(reportes)
                                    }} />
                                </Tab>}
                            {(restauranteordendomicilio) &&
                                <Tab eventKey="Orden Domicilio" title="Orden Domicilio">
                                    <RestauranteOrdenDomicilio mesa={"domicilio"} connection={connection}
                                        correrSocket={correrSocket} permisosusuario={permisosusuario}
                                        caja={cajaseleccionada} servidor={servidor} setrestauranteordendomicilio={(reportes) => {
                                            setrestauranteordendomicilio(reportes)
                                        }} />
                                </Tab>}
                            {(tercerosgas) &&
                                <Tab eventKey="Terceros Gas" title="Terceros Gas">
                                    <TercerosGas servidor={servidor} settercerosgas={(reportes) => {
                                        settercerosgas(reportes)
                                    }} />
                                </Tab>}

                            {(restauranteordenordenes) &&
                                <Tab eventKey="Ordenes En Proceso" title="Ordenes En Proceso">
                                    <RestauranteOrdenOrdenes mesa={"domicilio"} connection={connection}
                                        correrSocket={correrSocket} permisosusuario={permisosusuario}
                                        caja={cajaseleccionada} servidor={servidor} setrestauranteordenordenes={(reportes) => {
                                            setrestauranteordenordenes(reportes)
                                        }} />
                                </Tab>}
                            {(restauranteordenordenespormesero) &&
                                <Tab eventKey="Ordenes Mesero" title="Ordenes Mesero">
                                    <RestauranteOrdenOrdenespormesero mesa={"domicilio"} connection={connection}
                                        correrSocket={correrSocket} permisosusuario={permisosusuario}
                                        caja={cajaseleccionada} servidor={servidor} setrestauranteordenordenespormesero={(reportes) => {
                                            setrestauranteordenordenespormesero(reportes)
                                        }} />
                                </Tab>}

                            {(restauranteordenmesas) &&
                                <Tab eventKey="Mesas" title="Mesas">
                                    <RestauranteOrdenMesas vienedeafuera={false} mesa={"domicilio"} connection={connection}
                                        correrSocket={correrSocket} permisosusuario={permisosusuario}
                                        caja={cajaseleccionada} servidor={servidor} setrestauranteordenmesas={(reportes) => {
                                            setrestauranteordenmesas(reportes)
                                        }} />
                                </Tab>}

                            {(modeloscontables) &&
                                <Tab eventKey="Modelos Contables" title="Modelos Contables">
                                    <ModelosContables servidor={servidor} setmodeloscontables={(reportes) => {
                                        setmodeloscontables(reportes)
                                    }} />
                                </Tab>}
                            {(clasiarticulos) &&
                                <Tab eventKey="Clasi Productos" title="Clasi Productos">
                                    <Clasiarticulos servidor={servidor} setclasiarticulos={(reportes) => {
                                        setclasiarticulos(reportes)
                                    }} />
                                </Tab>}
                            {(tesoreria) &&
                                <Tab eventKey="Tesoreria" title="Tesoreria">
                                    <Tesoreria  permisosusuario={permisosusuario}  servidor={servidor} settesoreria={(reportes) => {
                                        settesoreria(reportes)
                                    }} />
                                </Tab>}
                            {(banco) &&
                                <Tab eventKey="Banco" title="Banco">
                                    <Banco servidor={servidor} setbaco={(reportes) => {
                                        setbanco(reportes)
                                    }} />
                                </Tab>}
                            {(sucursal) &&
                                <Tab eventKey="Sucursal" title="Sucursal">
                                    <Sucursal servidor={servidor} setbaco={(reportes) => {
                                        setsucursal(reportes)
                                    }} />
                                </Tab>}
                            {(tercerosdatosadicionales) &&
                                <Tab eventKey="Adicionales Tercero" title="Adicionales Tercero">
                                    <CrearTerceroAdicionales servidor={servidor} settercerosdatosadicionales={(reportes) => {
                                        settercerosdatosadicionales(reportes)
                                    }} />
                                </Tab>}

                            {(contabilidad) &&
                                <Tab eventKey="Contabilidad" title="Contabilidad">
                                    <Contabilidad servidor={servidor} setcontabilidad={(reportes) => {
                                        setcontabilidad(reportes)
                                    }} />
                                </Tab>}
                            {(abrircerrarperiodo) &&
                                <Tab eventKey="Abrir/Cerrar Periodo" title="Abrir/Cerrar Periodo">
                                    <Contperiodo servidor={servidor} setcontabilidad={(reportes) => {
                                        setcontabilidad(reportes)
                                    }} />
                                </Tab>}
                            {(cierreanual) &&
                                <Tab eventKey="Cierre Anual" title="Cierre Anual">
                                    <Cierreanual servidor={servidor} setcontabilidad={(reportes) => {
                                        setcontabilidad(reportes)
                                    }} />
                                </Tab>}
                            {(cociliancionbancaria) &&
                                <Tab eventKey="Conciliacion Bancaria" title="Conciliacion Bancaria">
                                    <ConciliacionBancaria servidor={servidor} setcontabilidad={(reportes) => {
                                        setcociliancionbancaria(reportes)
                                    }} />
                                </Tab>}
                            {(exogena) &&
                                <Tab eventKey="Exógena" title="Exógena">
                                    <Contabilidad servidor={servidor} setcontabilidad={(reportes) => {
                                        setcontabilidad(reportes)
                                    }} />
                                </Tab>}
                            {(correo) &&
                                <Tab eventKey="Correo" title="Correo">
                                    <Correo servidor={servidor} setcorreo={(reportes) => {
                                        setcorreo(reportes)
                                    }} />
                                </Tab>}
                            {(concorreo) &&
                                <Tab eventKey="Creacion Correo" title="Creacion Correo">
                                    <ConCorreo servidor={servidor} setconcorreo={(reportes) => {
                                        setconcorreo(reportes)
                                    }} />
                                </Tab>}
                            {(radian) &&
                                <Tab eventKey="Radian" title="Radian">
                                    <Radian servidor={servidor} setradian={(reportes) => {
                                        setradian(reportes)
                                    }} />
                                </Tab>}
                            {(comprobantescontabilidad) &&
                                <Tab eventKey="Comprobantes" title="Comprobantes">
                                    <Ctcomprobantes servidor={servidor} setcomprobantescontabilidad={(reportes) => {
                                        setcomprobantescontabilidad(reportes)
                                    }} />
                                </Tab>}
                            {(conclasiarticulos) &&
                                <Tab eventKey="Nombres Clasi Productos" title="Nombres Clasi Productos">
                                    <Conclasiarticulos servidor={servidor} setreportes={(reportes) => {
                                        setcierrecaja(reportes)
                                    }} />
                                </Tab>}
                            {(clasiterceros) &&
                                <Tab eventKey="Clasi Terceros" title="Clasi Terceros">
                                    <Clasiterceros servidor={servidor} setclasiterceros={(reportes) => {
                                        setclasiterceros(reportes)
                                    }} />
                                </Tab>}
                            {(conclasiterceros) &&
                                <Tab eventKey="Nombres Clasi Terceros" title="Nombres Clasi Terceros">
                                    <Conclasiterceros servidor={servidor} setreportes={(reportes) => {
                                        setcierrecaja(reportes)
                                    }} />
                                </Tab>}
                            {(cierrecaja) &&
                                <Tab eventKey="Cierre Caja" title="Cierre Caja">
                                    <CierreCaja permisosusuario={permisosusuario} caja={cajaseleccionada} servidor={servidor} setcierrecaja={(reportes) => {
                                        setcierrecaja(reportes)
                                    }} />
                                </Tab>}
                            {(cierrecajarestaurante) &&
                                <Tab eventKey="Cierre Caja R" title="Cierre Caja R">
                                    <CierreCajaRestaurante caja={cajaseleccionada} servidor={servidor} setcierrecaja={(reportes) => {
                                        setcierrecaja(reportes)
                                    }} />
                                </Tab>}
                            {(reportes) &&
                                <Tab eventKey="Informes" title="Informes">
                                    <Reportes caja={cajaseleccionada} permisosusuario={permisosusuario} servidor={servidor} setreportes={(reportes) => {
                                        setreportes(reportes)

                                    }} />
                                </Tab>}
                            {(reportes) &&
                                <Tab eventKey="Graficos" title="Graficos">
                                    <ReportesGraf caja={cajaseleccionada} permisosusuario={permisosusuario} servidor={servidor} setreportes={(reportes) => {
                                        setreportes(reportes)

                                    }} />
                                </Tab>}
                            {(maestrosnomina) &&
                                <Tab eventKey="Maestros Nomina" title="Maestros Nomina">
                                    <MaestrosNomina servidor={servidor} setmaestrosnomina={(reportes) => {
                                        setmaestrosnomina(reportes)

                                    }} />
                                </Tab>}
                            {(ctabonos) &&
                                <Tab eventKey="Cartera" title="Cartera">
                                    <Ctabonos permisosusuario={permisosusuario} caja={cajaseleccionada} servidor={servidor} setctabonos={(reportes) => {
                                        setctabonos(reportes)

                                    }} />
                                </Tab>}
                            {(cruceanticipo) &&
                                <Tab eventKey="Cruce Cartera" title="Cruce Cartera">
                                    <CruceAnticiposCartera permisosusuario={permisosusuario} caja={cajaseleccionada} servidor={servidor} setctabonos={(reportes) => {
                                        setcruceanticipo(reportes)

                                    }} />
                                </Tab>}
                            {(cruceanticipotesoreria) &&
                                <Tab eventKey="Cruce Cartera Proveedores" title="Cruce Cartera Proveedores">
                                    <CruceAnticiposTesoreria permisosusuario={permisosusuario} caja={cajaseleccionada} servidor={servidor} setctabonos={(reportes) => {
                                        setcruceanticipotesoreria(reportes)

                                    }} />
                                </Tab>}

                            {(procesoscontabilidad) &&
                                <Tab eventKey="Procesos Contabilidad" title="Procesos Contabilidad">
                                    <ProcesosContabilidad servidor={servidor} setprocesoscontabilidad={(reportes) => {
                                        setprocesoscontabilidad(reportes)

                                    }} />
                                </Tab>}
                            {(datosbasicoscontabilidad) &&
                                <Tab eventKey="Datos Basicos C" title="Datos Basicos C">
                                    <DatosBasicosContabilidad servidor={servidor} setdatosbasicoscontabilidad={(reportes) => {
                                        setdatosbasicoscontabilidad(reportes)

                                    }} />
                                </Tab>}
                            {(periodoscontabilidad) &&
                                <Tab eventKey="Periodos" title="Periodos">
                                    <Contperiodo servidor={servidor} setperiodoscontabilidad={(reportes) => {
                                        setperiodoscontabilidad(reportes)

                                    }} />
                                </Tab>}
                            {(plandecuentas) &&
                                <Tab eventKey="Plan de Cuentas" title="Plan de Cuentas">
                                    <Plandecuentas servidor={servidor} setplandecuentas={(reportes) => {
                                        setplandecuentas(reportes)

                                    }} />
                                </Tab>}
                            {(prestacionessocialesnomina) &&
                                <Tab eventKey="Prestaciones Sociales" title="Prestaciones Sociales">
                                    <PrestacionesSocialesNomina servidor={servidor} setprestacionessocialesnomina={(reportes) => {
                                        setprestacionessocialesnomina(reportes)

                                    }} />
                                </Tab>}
                            {(procesosnomina) &&
                                <Tab eventKey="Procesos Nomina" title="Procesos Nomina">
                                    <ProcesosNomina servidor={servidor} setreportes={(reportes) => {
                                        setprocesosnomina(reportes)

                                    }} />
                                </Tab>}
                            {(producto) &&
                                <Tab eventKey="Articulos" title="Articulos">
                                    <Producto permisosusuario={permisosusuario} servidor={servidor} setproducto={(producto) => {
                                        setproducto(producto)

                                    }} />
                                </Tab>}
                            {(usuario) &&
                                <Tab eventKey="Usuario" title="Usuario">
                                    <Usuario servidor={servidorempresa} usuariounico={usuariounico} setusuario={(usuario) => {
                                        setusuario(usuario)
                                        // setusuariounico(false)
                                    }} />
                                </Tab>}
                            {(categoria) &&
                                <Tab eventKey="Categoria" title="Categoria">
                                    <Categoria servidor={servidor} setcategoria={(categoria) => {
                                        setcategoria(categoria)

                                    }} />
                                </Tab>}
                            {(false) &&
                                <Tab eventKey="Historial" title="Historial">
                                    <DashBoard servidor={servidor} setdashboard={(dashboard) => {
                                        setdashboard(dashboard)

                                    }} />
                                </Tab>}
                            {(historialventa) &&
                                <Tab eventKey="Historial Venta" title="Historial Venta">
                                    <HistorialVenta servidor={servidor} sethistorialventa={(historialventa) => {
                                        sethistorialventa(historialventa)

                                    }} />
                                </Tab>}
                            {(reporteVenta) &&
                                <Tab eventKey="Reporte Venta" title="Reporte Venta">
                                    <ReporteVenta servidor={servidor} setreporteVenta={(reporteventa) => {
                                        setreporteVenta(reporteventa)

                                    }} />
                                </Tab>}
                            {(venta) &&
                                <Tab eventKey="Movimientos" title="Movimientos">
                                    <Venta connection={connection} correrSocket={correrSocket} permisosusuario={permisosusuario} caja={cajaseleccionada} servidor={servidor} setventa={(venta) => {
                                        setventa(venta)

                                    }} />
                                </Tab>}
                            {(ventadrogueria) &&
                                <Tab eventKey="Movimientos" title="Movimientos">
                                    <Ventadrogueria servidor={servidor} setventa={(ventadrogueria) => {
                                        setventadrogueria(ventadrogueria)

                                    }} />
                                </Tab>}
                            {(inicio) &&
                                <Tab eventKey="Inicio" title="Inicio">
                                    <Inicio servidor={servidor} setinicio={(inicio) => {
                                        setinicio(inicio)

                                    }} />
                                </Tab>}
                            {(grupo) &&
                                <Tab eventKey="Grupo" title="Grupo">
                                    <Grupo servidor={servidorempresa} usuariounico={usuariounico} setgrupo={(grupo) => {
                                        setgrupo(grupo)

                                    }} />
                                </Tab>}
                            {(permisosdetallado) &&
                                <Tab eventKey="Permisos" title="Permisos">
                                    <Permisos cajasbusqueda={cajasbusqueda} servidor={servidor} servidorempresa={servidorempresa} usuariounico={true} setpermisosdetallado={(grupo) => {
                                        setpermisosdetallado(grupo)

                                    }} />
                                </Tab>}

                            {(empresa) &&
                                <Tab eventKey="Empresa" title="Empresa">
                                    <Empresa servidor={servidorempresa} setempresa={(empresa) => {
                                        setempresa(empresa)

                                    }} />
                                </Tab>}
                            {(ringcallrecepcion) &&
                                <Tab eventKey="Ring Call Recepcion" title="Ring Call Recepcion">
                                    <RingCallRecepcion servidor={servidor} setringcallrecepcion={(ringcallrecepcion) => {
                                        setringcallrecepcion(ringcallrecepcion)

                                    }} />
                                </Tab>}
                            {(creartercero) &&
                                <Tab eventKey="Terceros" title="Terceros">
                                    <CrearTercero servidor={servidor} setcreartercero={(creartercero) => {
                                        setcreartercero(creartercero)

                                    }} />
                                </Tab>}


                            {(crearvendedor) &&
                                <Tab eventKey="Vendedores" title="Vendedores">
                                    <CrearVendedor servidor={servidor} setcrearvendedor={(crearvendedor) => {
                                        setcrearvendedor(crearvendedor)

                                    }} />
                                </Tab>}
                            {(crearpais) &&
                                <Tab eventKey="Paises" title="Paises">
                                    <CrearPais servidor={servidor} setcrearpais={(crearpais) => {
                                        console.log(servidor)
                                        setcrearpais(crearpais)

                                    }} />
                                </Tab>}
                            {(crearmunicipio) &&
                                <Tab eventKey="Municipios" title="Municipios">
                                    <CrearMunicipio servidor={servidor} setcrearmunicipio={(crearmunicipio) => {
                                        setcrearmunicipio(crearmunicipio)

                                    }} />
                                </Tab>}
                            {(creardepartamento) &&
                                <Tab eventKey="Departamentos" title="Departamentos">
                                    <CrearDepartamento servidor={servidor} setcreardepartamento={(creardepartamento) => {
                                        setcreardepartamento(creardepartamento)

                                    }} />
                                </Tab>}
                            {(crearlistadeprecio) &&
                                <Tab eventKey="Lista de Precios" title="Lista de Precios">
                                    <CrearListadePrecio servidor={servidor} setcrearlistadeprecio={(crearlistadeprecio) => {
                                        setcrearlistadeprecio(crearlistadeprecio)

                                    }} />
                                </Tab>}
                            {(crearcentrodecosto) &&
                                <Tab eventKey="Centros de Costo" title="Centros de Costo">
                                    <CrearCentrodeCosto servidor={servidor} setcrearcentrodecosto={(crearcentrodecosto) => {
                                        setcrearcentrodecosto(crearcentrodecosto)

                                    }} />
                                </Tab>}
                            {(reconstruircontabilidad) &&
                                <Tab eventKey="Reconstruir" title="Reconstruir">
                                    <Reconstruir servidor={servidor} setreconstruircontabilidad={(crearcentrodecosto) => {
                                        setreconstruircontabilidad(crearcentrodecosto)

                                    }} />
                                </Tab>}
                            {(crearbodega) &&
                                <Tab eventKey="Bodegas" title="Bodegas">
                                    <CrearBodega servidor={servidor} setcrearbodega={(crearbodega) => {
                                        setcrearbodega(crearbodega)

                                    }} />
                                </Tab>}
                            {(creartipodocumento) &&
                                <Tab eventKey="Tipos de Documento" title="Tipos de Documento">
                                    <CrearTipoDocumento  permisosusuario={permisosusuario}  servidor={servidor} setcreartipodocumento={(creartipodocumento) => {
                                        setcreartipodocumento(creartipodocumento)

                                    }} />
                                </Tab>}
                            {(chat) &&
                                <Tab eventKey="Chat" title="Chat">
                                    <Chat connection={connection} correrSocket={correrSocket} servidor={servidor} servidorempresa={servidorempresa} setchat={(chat) => {
                                        setchat(chat)

                                    }} />
                                </Tab>}
                            {(crearmediodepagodian) &&
                                <Tab eventKey="Medios de Pago Dian" title="Medios de Pago Dian">
                                    <CrearMediodePagoDian servidor={servidor} setcrearmediodepagodian={(crearmediodepagodian) => {
                                        setcrearmediodepagodian(crearmediodepagodian)

                                    }} />
                                </Tab>}
                            {(crearmediodepago) &&
                                <Tab eventKey="Medios de Pago" title="Medios de Pago">
                                    <CrearMediodePago servidor={servidor} setcrearmediodepago={(crearmediodepago) => {
                                        setcrearmediodepago(crearmediodepago)

                                    }} />
                                </Tab>}
                            {(backup) &&
                                <Tab eventKey="Backup" title="Backup">
                                    <Backup servidor={servidor} setbackup={(crearmediodepago) => {
                                        setbackup(crearmediodepago)

                                    }} />
                                </Tab>}
                        </Tabs>
                        {
                            mostrarlogo &&
                            <div>
                                <img src={Icono} alt="Image" className="img-fluid" />
                            </div>
                        }

                        {/* <Outlet /> */}

                    </div>
                    <Modal size="lg" isOpen={seleccionarcaja}>
                        <ModalHeader>
                            Caja
                        </ModalHeader>
                        <ModalBody>
                            <Row>

                                <Col sm={12}>
                                    <FormGroup>

                                        <Input value={cajaseleccionada} onChange={(e) => {
                                            var cajafi = ""
                                            var listacajafija = []
                                            permisosusuario.forEach(element => {
                                                if (element.tipo == "cajafija") {
                                                    cajafi = element.tipodoc
                                                    listacajafija.push(element.tipodoc)
                                                }
                                            });
                                            if (cajafi == "") {
                                                setcajaseleccionada(e.target.value)

                                            } else {
                                                if (listacajafija.length > 1) {
                                                    listacajafija.forEach(element => {
                                                        if (element == e.target.value) {
                                                            setcajaseleccionada(e.target.value)

                                                        }
                                                    });
                                                } else {
                                                    setcajaseleccionada(cajafi)

                                                }

                                            }

                                        }} type="select" style={{ fontSize: '13px' }} name="cajainicio"  >
                                            {cajasbusqueda.map((dato) => {
                                                return <option value={dato.codigo}>{dato.nombre}</option>
                                            })}

                                        </Input>
                                    </FormGroup>
                                </Col>
                            </Row>
                        </ModalBody>
                        <ModalFooter>

                            <Button size="sm" color="primary" onClick={() => {
                                if (cajaseleccionada != "") {
                                    setseleccionarcaja(false)

                                }
                            }}>Seleccionar</Button>
                        </ModalFooter>
                    </Modal>
                </div>
                <footer className="sticky-footer bg-white">
                    <div className="container my-auto">
                        <div className="copyright text-center my-auto">
                            <span>Copyright &copy;Solifactin S.A.S 2022</span>
                        </div>
                    </div>
                </footer>
            </div>


        </>
    )
}

export default App