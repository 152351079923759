import { Card, CardBody, CardHeader, Col, FormGroup, Input, InputGroup, InputGroupText, Label, Row, Table, Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import DatePicker from "react-datepicker";
import Swal from 'sweetalert2'
import DataTable from 'react-data-table-component';
import "react-datepicker/dist/react-datepicker.css";
import React, { useEffect, useState, useContext } from "react";
import { UserContext } from '../../../context/UserProvider';
import * as XLSX from "xlsx"
import { FormatMoney } from 'format-money-js'
import Filtro from '../../../componentes/filtro';
const tabespacio = '\u00A0';

const modeloInicio = [{
    referencia: "",
    descripcion: "",
    bodega: "",
    existencia: ""

}]

const ReporteVenta = (props) => {
    const [fechaInicio, setFechaInicio] = useState(new Date());
    const [fechaFin, setFechaFin] = useState(new Date());
    const [pendiente, setPendiente] = useState(false);
    const [ventas, setVentas] = useState(modeloInicio)
    const { user, cerrarSession } = useContext(UserContext);
    const [fm, setfm] = useState(new FormatMoney({ symbol: '', decimals: 2 }));
    const [filtros, setfiltros] = useState(false);
    const [mayora, setmayora] = useState(false);
    const [tipodocumentoinicioseleccionado, settipodocumentoinicioseleccionado] = useState("-");
    const [tipodocumentofinseleccionado, settipodocumentofinseleccionado] = useState("-");
    const [referenciafinseleccionado, setreferenciafinseleccionado] = useState("-");
    const [referenciainicioseleccionado, setreferenciainicioseleccionado] = useState("-");
    const [clasificacionarticulo1, setclasificacionarticulo1] = useState("-");
    const [coincidencianombre, setcoincidencianombre] = useState("-");
    const [bodega, setbodega] = useState("-");
    const [sucursal, setsucursal] = useState("-");
    const [fm3, setfm3] = useState(new FormatMoney({ symbol: '$ ', decimals: 2 }));

    const buscar = () => {

        setPendiente(true)
        // let options = { year: 'numeric', month: '2-digit', day: '2-digit' };

        // let _fechaInicio = fechaInicio.toLocaleDateString('es-PE', options)
        // let _fechaFin = fechaFin.toLocaleDateString('es-PE', options)
        let dt = JSON.parse(user)
        var coincidencia = ''
        var dsdsd = props.servidor + '/Inarticulosapi/reporteexistencia/existencia/' + mayora + "/" + tipodocumentoinicioseleccionado + "/" + tipodocumentofinseleccionado + dt.empresa.config.identificador_software + "/" + referenciainicioseleccionado + "/" + referenciafinseleccionado + "/" + clasificacionarticulo1 + "/" + coincidencianombre + '?llave=' + dt.usuario.llaveempresa + ''
        const api = fetch(props.servidor + '/Inarticulosapi/reporteexistencia/existenciasucu/' + mayora + "/" + tipodocumentoinicioseleccionado + "/" + tipodocumentofinseleccionado + "/" + dt.empresa.config.identificador_software + "/" + referenciainicioseleccionado + "/" + referenciafinseleccionado + "/" + clasificacionarticulo1 + "/" + coincidencianombre + "/" + bodega + '/' + sucursal + '?llave=' + dt.usuario.llaveempresa + '')
            .then((response) => {
                return response.ok ? response.json() : Promise.reject(response);
            })
            .then((dataJson) => {
                var data = dataJson;
                setPendiente(false)
                if (data.length < 1) {
                    Swal.fire(
                        'Opps!',
                        'No se encontraron resultados',
                        'warning'
                    )
                }
                setVentas(data);
            }).catch((error) => {
                setVentas([]);
                Swal.fire(
                    'Opps!',
                    'No se pudo encontrar información',
                    'error'
                )
            })
    }
    const totaltotal = () => {
        var tot = 0
        for (let i = 0; i < ventas.length; i++) {
            const element = ventas[i];
            tot = tot + element.existencia
        }
        return tot
    }
    const totalcosto = () => {
        var tot = 0
        for (let i = 0; i < ventas.length; i++) {
            const element = ventas[i];
            tot = tot + element.costo
        }
        return tot
    }
    const totalcosto2 = () => {
        var tot = 0
        for (let i = 0; i < ventas.length; i++) {
            const element = ventas[i];
            tot = tot + (element.costo * element.existencia)
        }
        return tot
    }
    const CustomDescripcion = ({ row }) => (
        <div>
            { }
            <div>
                <div
                >
                    { }
                    {row.descrip}
                </div>
            </div>
        </div>
    );
    const exportarExcel = () => {
        var wb = XLSX.utils.book_new();
        var _ventas = [];

        // Procesamos las ventas y calculamos totales
        let totalExistencia = 0;
        let totalCostoUni = 0;
        let totalCostoTOT = 0;

        ventas.forEach(element => {
            var venn = {
                Referencia: element.referencia,
                Descripcion: element.descrip,
                Bodega: element.bodega,
                Existencia: fm.from(Number(element.existencia)),
                CostoUni: fm.from(Number(element.costo)),
                CostoUniConIva: fm.from(Number(element.costo) + (Number(element.costo) * (Number(element.iva) / 100))),
                CostoTOT: fm.from(Number(element.costo) * Number(element.existencia)),
            };

            // Sumamos los valores para los totales
            totalExistencia += Number(element.existencia);
            totalCostoUni += Number(element.costo);
            totalCostoTOT += (Number(element.costo) * Number(element.existencia));

            _ventas.push(venn);
        });

        // Agregamos la fila de totales
        _ventas.push({
            Referencia: "Totales",
            Descripcion: "",
            Bodega: "",
            Existencia: fm.from(totalExistencia),
            CostoUni: fm.from(totalCostoUni),
            CostoUniConIva: "",  // No aplica un total para Costo Uni con IVA
            CostoTOT: fm.from(totalCostoTOT)
        });

        var ws = XLSX.utils.json_to_sheet(_ventas);

        // Configuramos el formato de las celdas como tipo moneda (CostoUni, CostoUniConIva, CostoTOT)
        const range = XLSX.utils.decode_range(ws['!ref']);

        // Establecemos el formato de las celdas numéricas en el rango de filas y columnas
        for (let R = range.s.r + 1; R <= range.e.r; ++R) {
            // Formato moneda para las columnas Costo Uni, Costo Uni con Iva, Costo TOT (índices 4, 5, 6)
            ws[XLSX.utils.encode_cell({ r: R, c: 4 })].z = "$#,##0.00"; // Costo Uni
            ws[XLSX.utils.encode_cell({ r: R, c: 5 })].z = "$#,##0.00"; // Costo Uni con Iva
            ws[XLSX.utils.encode_cell({ r: R, c: 6 })].z = "$#,##0.00"; // Costo TOT
        }

        XLSX.utils.book_append_sheet(wb, ws, "Reporte");
        XLSX.writeFile(wb, "Reporte Catalogo Productos.xlsx");

    }

    const columns = [
        {
            name: 'Referencia',
            selector: row => row.referencia,
            sortable: true,
        },
        {
            name: 'Descripcion',
            selector: row => row.descrip,
            sortable: true,
            cell: row => <CustomDescripcion row={row} />,
        },
        {
            name: 'Bodega',
            selector: row => row.bodega,
            sortable: true,
        },
        {
            name: 'Existencia',
            selector: row => fm.from(Number(row.existencia)),
            sortable: true,
        },
        {
            name: 'Costo Uni',
            selector: row => fm.from(Number(row.costo)),
            sortable: true,
        },
        {
            name: 'Costo Uni con Iva',
            selector: row => fm.from(Number(row.costo) + (Number(row.costo) * (Number(row.iva) / 100))),
            sortable: true,
        },
        {
            name: 'Costo TOT',
            selector: row => fm.from((Number(row.costo) * Number(row.existencia))),
            sortable: true,
        }
    ];
    const paginationComponentOptions = {
        rowsPerPageText: 'Filas por página',
        rangeSeparatorText: 'de',
        selectAllRowsItem: true,
        selectAllRowsItemText: 'Todos',
    };
    const customStyles = {
        headCells: {
            style: {
                fontSize: '13px',
                fontWeight: 800,
            },
        },
        headRow: {
            style: {
                backgroundColor: "#eee",
            }
        }
    };


    return (
        <>
            <Row>
                <Col sm={12}>
                    <Card>
                        <CardHeader style={{ backgroundColor: '#188BAD', color: "white" }}>
                            Existencia por Bodega
                        </CardHeader>
                        <CardBody>
                            <Row className="align-items-end">
                                {/*                                 
                                <Col sm={3}>
                                    <FormGroup>
                                        <Label>Fecha Inicio:</Label>
                                        <DatePicker
                                            className="form-control form-control-sm"
                                            selected={fechaInicio}
                                            onChange={(date) => setFechaInicio(date)}
                                            dateFormat='dd/MM/yyyy'
                                        />
                                    </FormGroup>
                                </Col>

                                <Col sm={3}>
                                    <FormGroup>
                                        <Label>Fecha Fin:</Label>
                                        <DatePicker
                                            className="form-control form-control-sm"
                                            selected={fechaFin}
                                            onChange={(date) => setFechaFin(date)}
                                            dateFormat='dd/MM/yyyy'
                                        />
                                    </FormGroup>
                                </Col> */}
                                <Col sm={2}>
                                    <FormGroup>
                                        <Label style={{ fontSize: '13px' }}>{tabespacio}</Label>
                                        <Button style={{ backgroundColor: 'darkmagenta' }} size="sm" block onClick={() => {
                                            setfiltros(true)
                                        }}>
                                            <i className="fa fa-search" aria-hidden="true"></i> Filtros
                                        </Button>
                                    </FormGroup>
                                </Col>
                                <Col sm={3}>
                                    <FormGroup>
                                        <Button color="primary" size="sm" block onClick={buscar}>
                                            <i className="fa fa-search" aria-hidden="true"></i> Buscar
                                        </Button>
                                    </FormGroup>
                                </Col>

                                <Col sm={3}>
                                    <FormGroup>
                                        <Button color="success" size="sm" block onClick={exportarExcel}>
                                            <i className="fa fa-file-excel" aria-hidden="true"></i> Exportar
                                        </Button>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <hr></hr>
                            <Row>


                                <Col xs={4}>

                                    <div className="card border-left-success shadow" style={{ backgroundColor: '#8bc34a', fontSize: '0.6rem' }}>
                                        <div className="card-body p-2">
                                            <div className="row no-gutters align-items-center">
                                                <div className="col-12">
                                                    <div className="text-xs font-weight-bold text-uppercase" style={{ color: '#FFFFFF', fontSize: '0.6rem' }}>
                                                        Existencias Totales
                                                    </div>
                                                    <div className="h5 mb-0 font-weight-bold " style={{ color: '#FFFFFF' }}>{totaltotal()}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                                <Col xs={4}>

                                    <div className="card border-left-success shadow" style={{ backgroundColor: '#8bc34a', fontSize: '0.6rem' }}>
                                        <div className="card-body p-2">
                                            <div className="row no-gutters align-items-center">
                                                <div className="col-12">
                                                    <div className="text-xs font-weight-bold text-uppercase" style={{ color: '#FFFFFF', fontSize: '0.6rem' }}>
                                                        Costo Total Unitario
                                                    </div>
                                                    <div className="h5 mb-0 font-weight-bold " style={{ color: '#FFFFFF' }}>{fm3.from(totalcosto())}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                                <Col xs={4}>

                                    <div className="card border-left-success shadow" style={{ backgroundColor: '#8bc34a', fontSize: '0.6rem' }}>
                                        <div className="card-body p-2">
                                            <div className="row no-gutters align-items-center">
                                                <div className="col-12">
                                                    <div className="text-xs font-weight-bold text-uppercase" style={{ color: '#FFFFFF', fontSize: '0.6rem' }}>
                                                        Costo Total Unitario
                                                    </div>
                                                    <div className="h5 mb-0 font-weight-bold " style={{ color: '#FFFFFF' }}>{fm3.from(totalcosto2())}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                            <Row>

                                <Col sm="12">
                                    <DataTable
                                        progressPending={pendiente}
                                        columns={columns}
                                        data={ventas}
                                        customStyles={customStyles}
                                        pagination
                                        paginationComponentOptions={paginationComponentOptions}
                                    />


                                </Col>

                            </Row>

                        </CardBody>
                    </Card>
                </Col>
                <Filtro
                    setmayora={(e) => { setmayora(e) }}
                    settipodocumentoinicioseleccionado={(e) => { settipodocumentoinicioseleccionado(e) }}
                    settipodocumentofinseleccionado={(e) => { settipodocumentofinseleccionado(e) }}
                    setclasificacionarticulo1={(e) => { setclasificacionarticulo1(e) }}
                    mayora={mayora}
                    coincidencianombre={coincidencianombre}
                    setbodega={(e) => { setbodega(e) }}
                    setsucursal={(e) => { setsucursal(e) }}
                    bodega={bodega}
                    sucursal={sucursal}

                    tipodocumentoinicioseleccionado={tipodocumentoinicioseleccionado}
                    tipodocumentofinseleccionado={tipodocumentofinseleccionado}
                    referenciafinseleccionado={referenciafinseleccionado}
                    referenciainicioseleccionado={referenciainicioseleccionado}
                    clasificacionarticulo1={clasificacionarticulo1}
                    setcoincidencianombre={(e) => { setcoincidencianombre(e) }}
                    setreferenciafinseleccionado={(e) => { setreferenciafinseleccionado(e) }}
                    setreferenciainicioseleccionado={(e) => { setreferenciainicioseleccionado(e) }}
                    servidor={props.servidor} verModal={filtros} cerrarModal={() => { setfiltros(false) }} />

            </Row>


        </>
    )
}

export default ReporteVenta;