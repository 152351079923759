import React, { useContext } from 'react'
import { Navigate, Outlet } from 'react-router-dom'
import { Link } from 'react-router-dom';
import { UserContext } from '../../../context/UserProvider';
import Swal from 'sweetalert2'
import { useState } from 'react';
import Tabs from 'react-bootstrap/Tabs'
import Tab from 'react-bootstrap/Tab'
import ReporteExistenciaArticulos from './ReporteExistenciaArticulos';
import ReporteExistenciaArticulosespecial from './ReporteExistenciaArticulosespecial';
import ReporteCatalogoArticulos from './ReporteCatalogoArticulos';
import ReporteListaPrecio from './ListadePrecioHorizontal';
import ReporteCostoMercanciaVendida from './ReporteCostoMercanciaVendida';
import ReporteLibroAuxiliar from './ReporteLibroAuxiliar';
import ReporteExistenciaArticulosDrogueria from './Drogueria/ReporteExistenciaArticulosDrogueria';
import InicioBotones from './iniciobotones';
import ReporteExistenciaArticulosLote from './ReporteExistenciaArticulosLote';
import ReporteCostoMercanciaVendidaCaja from './ReporteCostoMercanciaVendidaCaja';
import ProductosPorVendedor from './ProductosPorVendedor';
import VentasporVendedor from './VentasporVendedor';
import ComisionesporVendedor from './ComisionesporVendedor';

const modelo = {
    nombre: "",
    correo: "",
    idRolNavigation: {
        idRol: 0,
        descripcion: ""
    }
}

const Reportes = (props) => {
    const { user, cerrarSession } = useContext(UserContext)
    const [key, setKey] = React.useState('Inicio');
    const [producto, setproducto] = React.useState(false);
    const [usuario, setusuario] = React.useState(false);
    const [categoria, setcategoria] = React.useState(false);
    const [dashboard, setdashboard] = React.useState(false);
    const [reporteExistenciaArticulos, setreporteExistenciaArticulos] = React.useState(false);
    const [reporteExistenciaArticulosespecial, setreporteExistenciaArticulosespecial] = React.useState(false);
    const [reporteCatalogoArticulos, setreporteCatalogoArticulos] = React.useState(false);
    const [reporteListadePrecio, setreporteListadePrecio] = React.useState(false);
    const [reporteCostoMercanciaVendida, setreporteCostoMercanciaVendida] = React.useState(false);
    const [reporteLibroAuxiliar, setreporteLibroAuxiliar] = React.useState(false);
    const [inicio, setinicio] = React.useState(false);
    const [reporteExistenciaArticulosDrogueria, setreporteExistenciaArticulosDrogueria] = React.useState(false);
    const [reporteExistenciaArticulosLote, setreporteExistenciaArticulosLote] = React.useState(false);
    const [reporteCostoMercanciaVendidaCaja, setreporteCostoMercanciaVendidaCaja] = React.useState(false);
    const [prodcutosporvendedor, setprodcutosporvendedor] = React.useState(false);
    const [ventasporvendedor, setventasporvendedor] = React.useState(false);
    const [comisionesporvendedor, setcomisionesporvendedor] = React.useState(false);
    
    // const [reporteVenta, setreporteVenta] = React.useState(false);

    if (user == null) {
        return <Navigate to="/Login" />
    }



    return (
        <>
            {/*Content Wrapper*/}
            <div id="content-wrapper" className="d-flex flex-column">

                {/*Main Content*/}
                <div id="content">

                    <div className="container-fluid">

                        <Tabs
                            id="controlled-tab-example"
                            activeKey={key}
                            onSelect={(k) => setKey(k)}
                            className=" pestañas"
                        >
                            <Tab eventKey="Inicio" title="Inicio">
                                <InicioBotones servidor={props.servidor}
                                    setinicio={(reporteExistenciaArticulos) => {
                                        setinicio(reporteExistenciaArticulos)
                                    }}
                                    setreporteExistenciaArticulos={(reporteInventario) => {
                                        setreporteExistenciaArticulos(reporteInventario)
                                        setKey("Existencia Articulos")
                                    }}
                                    setreporteExistenciaArticulosespecial={(reporteInventario) => {
                                        setreporteExistenciaArticulosespecial(reporteInventario)
                                        setKey("Existencia Articulos Pre")
                                    }}
                                    setreporteExistenciaArticulosLote={(reporteInventario) => {
                                        setreporteExistenciaArticulosLote(reporteInventario)
                                        setKey("Existencia Articulos Lote")
                                    }}
                                    ReporteExistenciaArticulosLote
                                    setreporteExistenciaArticulosDrogueria={(reporteInventario) => {
                                        setreporteExistenciaArticulosDrogueria(reporteInventario)
                                        setKey("Existencia por Presentacion")
                                    }}

                                    setreporteCatalogoArticulos={(reporteInventario) => {
                                        setreporteCatalogoArticulos(reporteInventario)
                                        setKey("Catalogo de Articulos")
                                    }}
                                    setreporteListadePrecio={(reporteInventario) => {
                                        setreporteListadePrecio(reporteInventario)
                                        setKey("Lista de Precios")
                                    }}
                                    setreporteCostoMercanciaVendida={(reporteInventario) => {
                                        setreporteCostoMercanciaVendida(reporteInventario)
                                        setKey("Costo de Mercancia Vendida")
                                    }}
                                    setreporteLibroAuxiliar={(reporteInventario) => {
                                        setreporteLibroAuxiliar(reporteInventario)
                                        setKey("Libro Auxiliar")
                                    }}
                                    setreporteCostoMercanciaVendidaCaja={(reporteInventario) => {
                                        setreporteCostoMercanciaVendidaCaja(reporteInventario)
                                        setKey("Mercancia Vendida Por Caja")
                                    }}
                                    setcomisionesporvendedor={(reporteInventario) => {
                                        setcomisionesporvendedor(reporteInventario)
                                        setKey("Comisiones por Vendedor")
                                    }}
                                    
                                />
                            </Tab>
                            <Tab eventKey="Existencia Articulos" title="Existencia Articulos">
                                <ReporteExistenciaArticulos servidor={props.servidor} setreporteExistenciaArticulos={(reporteExistenciaArticulos) => {
                                    setreporteExistenciaArticulos(reporteExistenciaArticulos)
                                }} />
                            </Tab>
                            <Tab eventKey="Existencia Articulos Pre" title="Existencia Articulos Pre">
                                <ReporteExistenciaArticulosespecial servidor={props.servidor} setreporteExistenciaArticulosespecial={(reporteExistenciaArticulos) => {
                                    setreporteExistenciaArticulosespecial(reporteExistenciaArticulos)
                                }} />
                            </Tab>
                            <Tab eventKey="Existencia Articulos Lote" title="Existencia Articulos Lote">
                                <ReporteExistenciaArticulosLote servidor={props.servidor} setreporteExistenciaArticulosLote={(reporteExistenciaArticulos) => {
                                    setreporteExistenciaArticulosLote(reporteExistenciaArticulos)
                                }} />
                            </Tab>

                            <Tab eventKey="Catalogo de Articulos" title="Catalogo de Articulos">
                                <ReporteCatalogoArticulos servidor={props.servidor} setreporteCatalogoArticulos={(reporteCatalogoArticulos) => {
                                    setreporteCatalogoArticulos(reporteCatalogoArticulos)
                                }} />
                            </Tab>
                            <Tab eventKey="Lista de Precios" title="Lista de Precios">
                                <ReporteListaPrecio servidor={props.servidor} setreporteListadePrecio={(reporteListadePrecio) => {
                                    setreporteListadePrecio(reporteListadePrecio)
                                }} />
                            </Tab>
                            <Tab eventKey="Costo de Mercancia Vendida" title="Costo de Mercancia Vendida">
                                <ReporteCostoMercanciaVendida servidor={props.servidor} setreporteCostoMercanciaVendida={(reporteCostoMercanciaVendida) => {
                                    setreporteCostoMercanciaVendida(reporteCostoMercanciaVendida)
                                }} />
                            </Tab>
                            <Tab eventKey="Mercancia Vendida Por Caja" title="Mercancia Vendida Por Caja">
                                <ReporteCostoMercanciaVendidaCaja servidor={props.servidor} setreporteCostoMercanciaVendidaCaja={(reporteCostoMercanciaVendida) => {
                                    setreporteCostoMercanciaVendidaCaja(reporteCostoMercanciaVendida)
                                }} />
                            </Tab>
                            <Tab eventKey="Libro Auxiliar" title="Libro Auxiliar">
                                <ReporteLibroAuxiliar servidor={props.servidor} setreporteLibroAuxiliar={(reporteLibroAuxiliar) => {
                                    setreporteLibroAuxiliar(reporteLibroAuxiliar)
                                }} />
                            </Tab>
                            <Tab eventKey="Existencia por Presentacion" title="Existencia por Presentacion">
                                <ReporteExistenciaArticulosDrogueria servidor={props.servidor} setreporteExistenciaArticulosDrogueria={(reporteLibroAuxiliar) => {
                                    setreporteExistenciaArticulosDrogueria(reporteLibroAuxiliar)
                                }} />
                            </Tab>
                            <Tab eventKey="Productos por Vendedor" title="Productos por Vendedor">
                                <ProductosPorVendedor servidor={props.servidor} setprodcutosporvendedor={(reporteLibroAuxiliar) => {
                                    setprodcutosporvendedor(reporteLibroAuxiliar)
                                }} />
                            </Tab>
                            <Tab eventKey="Ventas por Vendedor" title="Ventas por Vendedor">
                                <VentasporVendedor servidor={props.servidor} setventasporvendedor={(reporteLibroAuxiliar) => {
                                    setventasporvendedor(reporteLibroAuxiliar)
                                }} />
                            </Tab>
                            <Tab eventKey="Comisiones por Vendedor" title="Comisiones por Vendedor">
                                <ComisionesporVendedor servidor={props.servidor} setcomisionesporvendedor={(reporteLibroAuxiliar) => {
                                    setcomisionesporvendedor(reporteLibroAuxiliar)
                                }} />
                            </Tab>
                            
                        </Tabs>

                        {/* <Outlet /> */}

                    </div>
                </div>

            </div>


        </>
    )
}

export default Reportes